.side-bar-link {
    text-decoration: none;
    color:#797979;
    margin-left: 13px;
  }
  .side-bar-link-selected {
    text-decoration: none !important;
    color: #fff !important;
    margin-left: 13px !important;
  }
  
  .form-center {
    margin: auto;
    width: 40%;
    padding: 10px;
  }
  
  .text-size-input {
    width: 500px;
  }
  
  .app-bar {
    background-color: #fff !important;
  }
  
  .nab-bar-arrow {
    color: #fff
  }
  
  .app-bar-dots {
    color: black;
  }
  
  .login-section {
    height: 100vh;
  }
  
  .login-card {
    width: 100%;
    max-width: 650px;
    box-shadow: 15px 15px 52px #afafaf !important;
  }
  
  .login-logo {
    height: 60px;
    object-fit: contain;
  }
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .dashboard-chart>div:first-child {
    width: 100% !important;
  }
  
  .dashboard-chart>div>canvas {
    width: 100% !important;
  }
  
  .dashboard-chart-scatter>div:first-child {
    width: 100% !important;
  }
  
  .dashboard-chart-scatter>div>svg {
    width: 100% !important;
  }
  
  .dashboard-chart-scatter-dashBoard>div:first-child {
    width: 100% !important;
  }
  
  .dashboard-chart-scatter-dashBoard>div>div {
    width: 100% !important;
  }
  
  .dashboard-chart-scatter-dashBoard>div>div>svg {
    width: 100% !important;
  }
  
  .apexcharts-legend {
    display: none !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    background-color: #000000;
    color:#fff !important;
    margin:10px;
  }
  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
@font-face {
  font-family: mulish-semibold;
  src: url(../fonts/Mulish-SemiBold.ttf);
}

@font-face {
  font-family: mulish-regular;
  src: url(../fonts/Mulish-Regular.ttf);
}

@font-face {
  font-family: mulish-bold;
  src: url(../fonts/Mulish-Bold.ttf);
}

@font-face {
  font-family: mulish-light;
  src: url(../fonts/Mulish-Light.ttf);
}

@font-face {
  font-family: cambria-math;
  src: url(../fonts/Cambria\ Math.ttf);
}

@font-face {
  font-family: open-sans-regular;
  src: url(../fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: open-sans-medium;
  src: url(../fonts/OpenSans-Medium.ttf);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.login-error-message {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 20px;
}

.container {
  /* max-width: 1140px; */
  max-width: 1300px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.sign-up-error-message {
  margin-top: 10px;
  color: #d32f2f;
}

.navigation-sec {
  border-bottom: 1px solid #dfdfdf;
}

.trending-sec {
  background-image: url('../images/png/trending-bg.png');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.trending-sec1 {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.catalogues-sec {
  background-image: url('../images/png/catalogues-bg.png');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.precious-sec {
  background-image: url('../images/png/catalogues-bg.png');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.antic-jewelry-sec {
  background-image: url('../images/png/antic.png');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* start footer section css */
.footer-items {
  box-shadow: none !important;
  background-color: transparent !important;
}

.footer-colum {
  background-color: #1a1a1a;
  box-shadow: none;
  border-radius: 0px;
}

.footer-sec {
  background-color: #1a1a1a;
  padding: 101px 0px 22px;
}

.footer-info-img {
  width: 14.89px;
  height: 14.89px;
  object-fit: contain;
  margin: 12px 26.5px 0px 0px;
}

.footer-info-box {
  font-size: 18px;
  line-height: 37px;
  font-family: mulish-semibold;
  color: #7b766e;
  display: flex;
  min-width: 235px;
}

.footer-logo-box {
  margin: -31px 0px 28px -15px;
}

.footer-logo-img {
  width: 96px;
  height: 76px;
  object-fit: contain;
}

.footer-title {
  font-size: 22px;
  line-height: 56px;
  color: #ffffff;
  font-family: cambria-math;
  position: relative;
}

.footer-title::after {
  content: '';
  width: 57px;
  background-color: #fff;
  height: 5px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 5px;
}

.footer-copyright-text-box .footer-copyright-text {
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  font-family: mulish-semibold;
}

.footer-copyright-sec {
  background-color: #c29958;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-ul-main .footer-ul {
  background-color: #1a1a1a;
  padding: 13px 0px 0px 0px;
}

.footer-ul .footer-li {
  padding: 0px;
}

.footer-ul a {
  text-decoration: none;
}

.footer-ul .footer-li {
  color: #7b766e;
  font-size: 18px;
  line-height: 37px;
  font-family: mulish-semibold;
}

/* end footer section css */

/* start trending section css */
.trending-sec {
  background-color: #ffffff;
  padding: 104px 0px 109px;
}

.sec-main-title {
  font-size: 50px;
  line-height: 69px;
  color: #000000;
  margin: 0px 0px 48px;
}

.sec-main-title1 {
  font-size: 50px;
  line-height: 69px;
  padding-left: 15px;
  padding-right: 15px;
  color: #000000;
  margin: 0px 0px 48px;
}

.sec-main-title2 {
  font-size: 50px;
  line-height: 69px;
  padding-left: 15px;
  margin-right: 8px !important;
  color: #000000;
  margin: 0px 0px 48px;
  background-color: rgb(191, 191, 191);
}

.product-box {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
  min-width: 255px;
  width: 100%;
}

.product-img {
  width: 100%;
  max-width: 263px;
  height: 267px;
  object-fit: contain;
}

.product-content {
  padding: 17px 9px 8px;
}

.product-title {
  font-size: 19px;
  line-height: 24px;
  font-family: mulish-bold;
  color: #000000;
  margin: 0px 0px 12px;
  text-align: center;
}

.muli-bold {
  font-family: 'mulish-bold' !important;
}

.product-sub-title {
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-semibold;
  color: #484848;
  margin: 0px 0px 11px;
  text-align: center;
  width: 100%;
  max-width: 246px;

}

.product-price {
  font-size: 23px;
  line-height: 29px;
  font-family: mulish-bold;
  color: #000000;
  margin: 0px 0px 18px;
  text-align: center;
}

.product-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-btn-box .btn-product {
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  color: #000000;
  background-color: #f9f0e3;
  text-align: center;
  width: 100%;
  border-radius: 0px;
  text-transform: capitalize;
  height: 45px;
}

.product-btn-box .btn-product:hover {
  background-color: #c29958;
}

/* end tranding section */

/* start catalogues section */
.catalogues-sec {
  background-color: #ffffff;
  padding: 0px 0px 96px;
}

.view-more-catalogues {
  width: 100% !important;
  max-width: 255px !important;
  float: right !important;
  margin: 10px 0px !important;
}

/* end catalogues section */

/* start precious section css */
.precious-sec {
  background-color: #f6f6f6;
  padding: 50px 0px 91px;
}

/* end precious section css */

/* Catalogues Page */

.catalogues-main-container {
  width: 100%;
  display: flex;
  max-width: 1110px;
  margin: auto;
  padding: 61px 0px 48px;
}

/* Catalogues Page */

.catalogues-details-main-container {
  background-color: #f6f6f6;
  padding: 61px 0px 48px;
}

.catalogues-details-order-container {
  display: flex;
  padding: 61px 0px 50px;
  max-width: 1140px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  box-sizing: border-box;
}

/* .catalogues-details-bulk {
  display: flex;
  align-items: center;
}

.catalogues-details-bulk-btn{
  width: 100%;
    min-width: 230px;
    margin-top: 21px;
}

.catalogues-details-order-field {
  display: flex;
}

.catalogues-details-bulk .addproduct-input-box .refrence-product-input.addproduct-input-box.MuiBox-root.css-0 {
  display: flex;
  align-items: center;
} */

.radio-main .input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-main .form-control input {
  max-width: 120px;
  height: 20px;
  width: 100%;
}

.radio-main .justify-custom {
  justify-content: center;
}

.radio-main .justify-custom p {
  margin: 0px 20px 6px 0;
}

/* end catalog css  */

/* start top deals section */
.top-deals-sec {
  padding: 0px 0px 75px;
}

.top-deals-main-box {
  position: relative;
}

.top-deals-details-box {
  position: absolute;
  right: 44px;
  top: 149px;
}

.top-deals-img {
  height: 374px;
  max-width: 540px;
  width: 100%;
  object-fit: cover;
}

.top-deals-sub-title {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 9px;
  color: #000000;
  font-family: cambria-math;
  margin: 0px 0px 2px;
}

.top-deals-title {
  font-size: 39px;
  line-height: 54px;
  color: #000000;
  margin: 0px 0px 12px;
  font-family: cambria-math;
  width: 200px;
}

.top-deals-btn-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.top-deals-btn-box .top-deals-btn {
  color: #000000;
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  border: 2px solid #000000;
  border-radius: 0px;
  text-transform: capitalize;
  width: 100%;
  max-width: 163px;
  height: 45px;
}

.top-deals-btn-box .top-deals-btn:hover {
  background-color: transparent;
}

/* start top deals section */

/* start antic jeweley section */
.antic-jewelry-sec {
  padding: 78px 0px 187px;
  background-color: #f6f6f6;
}

.sec-antic-title {
  font-size: 55px;
  line-height: 75px;
  color: #484848;
  font-family: cambria-math;
}

.antic-jewelry-content-box {
  padding: 24px;
  background-color: rgb(0 0 0 / 63%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
}

.antic-jewelry-sub-title {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 9px;
  color: #ffffff;
  margin: 0px 0px 11px;
  font-family: cambria-math;
}

.antic-jewelry-title {
  font-size: 37px;
  line-height: 37px;
  color: #ffffff;
  font-family: cambria-math;
}

.antic-jewelry-box {
  width: 100%;
  max-width: 350px;
  margin: 53px 0px 0px;
  position: relative;
}

.antic-jewelry-img {
  height: 576px;
  width: 100%;
  object-fit: cover;
}

.antic-jewelry-img2 {
  height: 476px;
  width: 100%;
  object-fit: cover;
}

.antic-jewelry-img3 {
  height: 476px;
  width: 100%;
  object-fit: cover;
}

.antic-jewelry-last-box {
  margin-top: -181px;
}

/* end antic jeweley section */

/* start banner section */

.banner-main {
  display: flex;
  width: 100%;
}

.banner-left-img-box {
  height: 100vh;
  width: 45%;
  max-width: 45%;
  flex: 0 0 45%;
  min-height: 693px;
}

.banner-right-img-box {
  height: 100vh;
  width: 55%;
  max-width: 55%;
  flex: 0 0 55%;
  min-height: 693px;
  position: relative;
}

.banner-right-img-box::after {
  position: absolute;
  content: '';
  left: 0px;
  height: 215px;
  width: 81px;
  background-image: url('../images/png/banner-yellow.png');
  top: 37%;
}

.banner-left-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  min-height: 693px;
}

.banner-right-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  min-height: 693px;
}

.bg-overlay {
  background: rgb(34 34 34 / 94%);
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 693px;
}

.banner-right-content {
  position: absolute;
  top: 31%;
  left: 27px;
}

.jewelry-text {
  font-size: 78px;
  line-height: 107px;
  color: #c29958;
}

.banner-sub-title {
  font-size: 28px;
  line-height: 39px;
  letter-spacing: 14px;
  color: #ffffff;
  font-family: cambria-math;
}

.banner-title {
  font-size: 78px;
  line-height: 107px;
  color: #ffffff;
  font-family: cambria-math;
}

/* end banner section */

/* start header css */

.header-main {
  padding: 15px 0px;
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
}

.header-logo-img {
  width: 114px;
  height: 90px;
  object-fit: cover;
  cursor: pointer;
}

.header-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-user-info {
  position: relative;
  padding: 0px 24px 0px 0px;
}

.header-user-info:last-child {
  padding: 0px;
}

.header-right-main .header-user-info-box {
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-cart-bg {
  height: 44px;
  width: 44px;
  background-color: #c29958;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px #00000030;
  cursor: pointer;
}

.cart-user-info {
  padding: 0px 14px 0px 0px !important;
}

.header-cart-img {
  height: 19px;
  width: 19px;
  object-fit: cover;
}

.header-user-img {
  height: 32px;
  width: 28px;
  object-fit: contain;
}

.navigation-baar .nav-items:active .nav-button {
  color: #946419;
}

.header-user-bg {
  height: 44px;
  width: 44px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #ffffff;
  cursor: pointer;
}

.header-user-info .header-link {
  text-decoration: none;
}

.header-link .header-link-text {
  font-size: 17px;
  line-height: 21px;
  color: #484848;
  font-family: mulish-bold;
  cursor: pointer;
}

.header-link-box {
  display: flex;
  padding: 0px 24px 0px 0px;
}

.header-search-input,
.search-main-header {
  position: relative;
}

.header-search-input .form-group {
  position: relative;
}

.header-search-form-control input {
  background-color: transparent;
  border: 1.5px solid #dfdfdf;
  border-radius: 25px;
  height: 47px;
  padding: 0px 50px 0px 148px;
  width: 100%;
  position: relative;
}

.header-search-input .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.header-select:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dfdfdf !important;
}

.header-select+.MuiSvgIcon-fontSizeMedium+.MuiOutlinedInput-notchedOutline {
  border: 1px solid #dfdfdf !important;
  border-color: #dfdfdf;
  border-width: 2px;
}

.header-search-form-control input::placeholder {
  color: #b8b8b8;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.header-vertical-line:after {
  content: '';
  display: block;
  background: #484848;
  width: 2px;
  height: 16.5px;
  position: absolute;
  right: 9px;
  top: 4px;
  margin: auto;
}

.search-grey-box {
  position: absolute;
  right: 15.67px;
  top: 15.67px;
}

/* start header css */

/* start faq css */
.faq-main-banner {
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  text-align: center;
  align-items: center;
}

.customer-main-banner {
  width: 100%;
  object-fit: contain;
  opacity: 0.86;
}

.hero-bgmain {
  position: relative;
}

.hero-bgtext {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.faq-accrodions .faq-accro-main {
  margin: 10px 0px !important;
}

.about-main-banner {
  background-image: linear-gradient(45deg,
      rgb(34 34 34 / 76%),
      rgb(34 34 34 / 76%)),
    url('../images/png/about-banner.png');
}

.faq-accrodions svg {
  color: #fff;
  height: 32px;
  width: 32px;
}

.faq-inner-content .lot-text {
  font-family: cambria-math;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: 14px;
  line-height: 39px;
  margin-bottom: 21px;
}

.faq-title-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: max-content;
  margin: auto;
}

.faq-title-main .faq-typo-custom {
  font-family: cambria-math;
  color: #ffffff;
  font-size: 78px;
  line-height: 107px;
  position: relative;
  z-index: 99;
  padding-left: 30px;
}

.img-side-yellow {
  position: absolute;
  left: 0px;
}

.yellow-left-banner {
  height: 133px;
  width: 81px;
}

.faq-question-main {
  padding: 50px 0px 50px 0px;
}

.title-faq-asked .frequently-text {
  font-family: 'cambria-math';
  color: #484848;
  font-size: 55px;
  line-height: 75px;
  text-align: center;
  margin-bottom: 51px;
}

.faq-accrodions .faq-accro-main {
  border-radius: 0px !important;
  border-bottom: 0px;
  box-shadow: none;
  background-color: #c29958;
  color: #ffffff;
  margin-bottom: 10px;
}

.faq-accrodions .typo-faq-accro {
  font-family: 'mulish-bold';
  font-size: 18px;
  line-height: 39px;
  width: 100%;
}

.details-content-faq {
  position: relative;
}

.faq-accrodions .details-content-faq-inner {
  line-height: 32px;
  font-size: 19px;
  font-family: mulish-regular;
}
.faq-accrodions .details-content-return-policy  {
  /* background-color: #f9f0e3; */
  color: #484848;
  padding: 17px 29px 16px 28px;
}


.faq-accrodions .details-content-faq {
  background-color: #f9f0e3;
  color: #484848;
  padding: 17px 29px 16px 28px;
}

.faq-accrodions .summary-faq-accro {
  padding: 17px 29px 16px 28px;
  min-height: 56 !important;
  height: 56px;
}

.faq-accrodions .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 56px !important;
  height: 56px;
}

.faq-accrodions .summary-faq-accro .MuiAccordionSummary-content {
  margin: 0px;
}

.down-up-accro {
  position: absolute;
  right: 29px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
}

.summary-faq-accro .Mui-expanded .typo-faq-accro .drop-up-accro {
  background-color: red;
}

.faq-accrodions .faq-accro-main:last-child {
  margin-bottom: 0px !important;
}

.bg-29958 {
  background-color: #c29958;
}

.customer-help-main {
  padding: 47px 15px 45px;
  text-align: center;
}

.title-inner-help .how-help-text {
  margin: 0px;
  font-family: cambria-math;
  font-size: 55px;
  color: #ffffff;
  line-height: 75px;
  margin-bottom: 40px;
  font-weight: 100;
}

.upper-content-services {
  padding: 0px 0px 47px 0px;
}

.customer-content-details {
  padding: 53px 0px 61px 0px;
}

.upper-content-services .title-typo-services {
  color: #484848;
  font-size: 25px;
  line-height: 32px;
  font-family: 'mulish-bold';
  margin-bottom: 22px;
}

.upper-content-services .title-typo-services-inner {
  color: #484848;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
}

.inner-box-services .title-card-services {
  color: #000000;
  font-size: 18px;
  line-height: 23px;
  font-family: 'mulish-regular';
  font-weight: 500;
  margin: 0px 0px 7px 0px;
}

.inner-box-services .desc-card-services {
  color: #484848;
  font-size: 18px;
  font-family: 'mulish-bold';
  line-height: 23px;
  margin: 0px;
  min-height: 49px;
  font-weight: 100;
}

.customer-services-cards .services-card {
  border-radius: 0px;
  box-shadow: 0px 4px 9px #c2995861;
}

.customer-services-cards .body-services-card {
  padding: 23px 41px 22px 27px;
}

.about-uslorem-main {
  background-image: url('../images/png/about-us-inside.png');
  background-size: cover;
  width: 100%;
  padding: 110px 0px 60px 0px;
}

.about-img-content {
  background-image: linear-gradient(180deg, #c29958, #c29958);
  padding: 9px 8px;
  display: flex;
}

.about-img-set {
  width: 100%;
  height: 534px;
  object-fit: cover;
}

.about-set-details .about-sets-title {
  color: #484848;
  font-size: 55px;
  line-height: 55px;
  font-family: 'cambria-math';
  margin-bottom: 52px;
}

.details-sets-us-text {
  color: #000000;
  font-size: 25px;
  line-height: 39px;
  font-family: 'mulish-bold';
  margin-bottom: 43px;
}

.set-lorem-aboutus .aboutus-inner-set {
  font-size: 19px;
  color: #484848;
  font-family: 'mulish-regular';
  line-height: 32px;
  margin-bottom: 28px;
}

.set-lorem-aboutus .aboutus-inner-set:first-child {
  min-height: 162px;
}

.why-choose-main {
  padding: 75px 0px 78px 0px;
}

.titile-inside-why .us-inner-why-chose {
  font-size: 50px;
  color: #000000;
  font-family: 'cambria-math';
  line-height: 50px;
  margin-bottom: 20px;
  text-align: center;
}

.titile-inside-why .map-inner-why-chose,
.testimonial-titles-main .testimonial-details-inner,
.ourteam-titles-main .team-details-inner {
  max-width: 634px;
  font-size: 19px;
  margin: auto;
  color: #484848;
  text-align: center;
  font-family: 'mulish-regular';
  margin-bottom: 53px;
  line-height: 32px;
}

.inner-box-aboutus .about-membership {
  width: 38px;
  height: 38px;
  object-fit: cover;
  margin-bottom: 19px;
}

.inner-box-aboutus {
  text-align: center;
}

.inner-box-aboutus .title-card-abouts {
  font-size: 22px;
  color: #000000;
  margin: 0px 0px 19px 0px;
  line-height: 28px;
  font-family: 'mulish-bold';
}

.inner-box-aboutus .desc-card-aboutus {
  font-size: 18px;
  font-family: 'mulish-regular';
  line-height: 32px;
  color: #484848;
  font-weight: 500;
  margin: 0px;
  word-wrap: break-word;
}

.about-card .body-about-card {
  padding: 30px 31px 29px 32px;
}

.box-card-about-details .about-card {
  box-shadow: 0px 4px 9px #c2995861;
  border-radius: 0px;
}

.testimonial-about-us {
  padding: 71px 0px 42px 0px;
  background: #f2f2f2;
}

.testimonial-titles-main .testimonial-title,
.ourteam-titles-main .team-title {
  color: #000000;
  font-size: 50px;
  font-family: 'cambria-math';
  text-align: center;
  margin: 0px 0px 20px 0px;
  line-height: 50px;
}

.our-team-about-us {
  padding: 68px 0px 217px 0px;
  background-color: #c29958;
}

.ourteam-titles-main .team-title,
.ourteam-titles-main .team-details-inner {
  color: #ffffff;
}

.team-img-main {
  text-align: center;
}

.team-img-main .img-team {
  height: 251px;
  width: 251px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #707070;
}

.user-aboutus-details {
  margin-top: -153px;
  padding-bottom: 79px;
}

.user-aboutus-details .user-about-card {
  background: transparent;
  box-shadow: 0px 4px 9px #c2995861;
  border-radius: 0px;
  padding: 126px 0px 0px 0px;
  margin-top: -103px;
}

.user-aboutus-details .body-usercontent {
  background: #ffff;
  padding: 0px 31px 4px 31px;
}

.ourteam-titles-main .team-details-inner {
  margin-bottom: 0px;
}

.body-usercontent .user-about-titles {
  font-size: 22px;
  color: #000000;
  text-align: center;
  margin-bottom: 4px;
  line-height: 28px;
  font-family: 'mulish-bold';
}

.body-usercontent .photo-about-user {
  color: #c29958;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  font-family: 'mulish-regular';
  margin-bottom: 14px;
}

.body-usercontent .user-about-inner-des {
  font-size: 18px;
  line-height: 26px;
  color: #484848;
  min-height: 95px;
  text-align: center;
  font-family: 'mulish-regular';
}

.icons-about-user {
  background: #f9f0e3;
  padding: 9px 31px 9px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons-about-user .icon-main-social:last-child {
  margin-right: 0px;
}

.icon-main-social {
  height: 47px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  background: #f9f0e3;
  border-radius: 50%;
}

.icons-about-user .icon-main-social:hover {
  background: #c29958;
}

.icons-about-user .icon-main-social:hover svg path {
  fill: #fff;
}

/* start faq css end */

.header-categories-main .header-categories-dropdown {
  padding: 0px;
  margin: 0px;
  max-width: 141px;
  height: 49px;
  min-width: 141px;
  width: 100%;
}

.header-categories-main .header-select {
  border-radius: 25px;
  background-color: #f6f6f6;
}

.header-categories-dropdown:hover {
  background-color: transparent;
}

.header-select div {
  padding: 15px 5px 14px 15px;
  font-size: 14px;
  line-height: 18px;
  font-family: mulish-semibold;
  color: #484848;
}

.header-categories-main svg {
  display: none;
}

.downgrey-img {
  height: 5.6px;
  width: 9.97px;
  object-fit: contain;
  position: absolute;
  top: 8px;
  right: 0px;
}

.header-categories-main {
  position: absolute;
  top: 0;
  left: 0;
}

.header-categories-main .header-categories-dropdown {
  margin: 0px;
}

.header-categories-main .header-categories-dropdown fieldset {
  border-color: #dfdfdf;
}

.place-an-order-error-message {
  color: red;
}

/* start header css */

/* start login modal css */
.modal-logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-logo-img {
  height: 90px;
  width: 114px;
  object-fit: contain;
  margin: auto;
}

.modal-content .modal-modal-title {
  font-size: 30px;
  line-height: 50px;
  color: #000000;
  text-align: center;
  font-family: cambria-math;
  /* margin: 38px 0px 25px; */
  /* margin: 8px 0px 8px; */
}

.modal-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-btn-box .modal-btn {
  font-size: 17px;
  line-height: 25px;
  font-family: mulish-bold;
  background-color: #c29958;
  width: 100%;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 0px;
  margin: 10px 0px 10px;
  height: 45px;
  margin-top: 10px !important;
}

.modal-btn-box .modal-btn-submit {
  margin-right: 5px !important;
}

.btn-dispatch-modal {
  margin-top: 0px;
}

.modal-btn-box .modal-btn:hover {
  background-color: #c29958;
  color: #ffffff;
}

.modal-content .modal-footer-para {
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-bold;
  color: #070505;
  text-align: center;
}

.modal-span {
  color: #c29958;
  cursor: pointer;
}

.header-modals {
  max-width: 631px;
  width: 100%;
  border-radius: 11px;
}

.input-box {
  margin: 0px 0px 17px;
  position: relative;
}

.input-box .input-lable {
  font-size: 14px;
  line-height: 18px;
  /* color: #3e3e3e; */
  color: black;
  font-family: mulish-semibold;
  margin: 0px 0px 6px;
}

.product-img-lable-main .product-img-lable {
  color: black;
  font-family: mulish-semibold;
}

.input-box .error-input-lable {
  font-size: 14px;
  line-height: 18px;
  color: #d32f2f;
  font-family: mulish-semibold;
  margin: 6px 0px 0px 0px;
}

.form-control input {
  padding: 8.5px;
  /* max-width: 559px; */
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f !important;
  font-family: mulish-semibold !important;
  position: relative;
}

.form-control1 input {
  padding: 12.5px;
  /* max-width: 559px; */
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f !important;
  font-family: mulish-semibold !important;
}

.form-control2 input {
  padding: 12.5px;
  /* max-width: 559px; */
  width: 100%;
  /* border: 1px solid #dfdfdf; */
  background-color: #ffffff;
  color: #8a8a8f !important;
  font-family: mulish-semibold !important;
  position: relative;
}

.input-box fieldset:focus {
  background-color: transparent;
  border-color: transparent;
}

.input-box fieldset {
  border-radius: 0px;
  border: 1px solid #dfdfdf;
}

.forgot-password-box .forgot-password-text {
  color: #c29958;
  font-size: 14px;
  line-height: 18px;
  font-family: mulish-semibold;
  text-align: end;
  margin-bottom: 10px;
}

.login-modal .css-8ttqws {
  padding: 24px 40px 24px;
  box-shadow: none;
  width: 100%;
  max-width: 450px;
  border-radius: 11px;
  border: 0px;
  outline: none;
  top: 48%;
  /* max-height: calc(100% - 150px); */
  overflow: auto;
}
.login-modal-logo-box{
  display: flex;
  justify-content: center;
}
/* .header-modals {
  padding: 30px 0px;
} */
/* width */
.login-modal ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.login-modal ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.login-modal ::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.login-modal ::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.placeorder-btn {
  margin: 26px 0px 0px !important;
}

.order-modal-sec [tabindex='-1'] {
  padding: 50px 40px 42px;
  border-radius: 11px;
  box-shadow: none;
  outline: none;
  top: 50%;
  border: 0px;
  overflow: auto;
  max-height: calc(100% - 150px);
}

.order-modal-sec .modal-btn-box .modal-btn {
  margin-bottom: 0;
}

/* .order-modal-sec .MuiBox-root{
  padding: 20px;
  max-width: 711px;
  top: 48%;
}  */
.order-modal .css-8ttqws {
  padding: 50px 40px 42px;
  border-radius: 11px;
  box-shadow: none;
  outline: none;
  top: 52%;
}

.MuiBackdrop-root {
  background-color: rgb(15 9 9 / 61%) !important;
}

.order-radio-box {
  display: flex;
}

.order-madal-title {
  margin-top: 0px !important;
}

.order-radio-box .radio-control {
  width: 100%;
}

.order-radio-box .radio-group {
  flex-wrap: nowrap;
  flex-direction: row;
}

.request-coustom-validation-err {
  color: red;
  margin-top: 10px;
}

.order-radio-box .radio-lable span {
  font-size: 14px;
  line-height: 18px;
  font-family: 'mulish-semibold';
  color: #000000;
}

.order-radio-box .radio-lable {
  margin-right: 57px;
}

.order-radio-box svg {
  color: #c29958;
}

.order-radio-box .radio-title {
  margin: 0px 0px 10px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

/* end login modal css */

/* start rings page */
.inner-hero-sec {
  background-color: #484848;
  height: 246px;
}

.rings-section {
  padding: 15px 0px 0px;
}

.rings-row2 {
  margin: 30px 0px;
}

.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 155px 0px 57px;
}

.pagination ul li:first-child {
  display: none;
}

.pagination ul li button {
  color: #c29958;
  border-radius: 0px;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #c29958;
  font-size: 12px;
  line-height: 15px;
  font-family: mulish-bold;
}

.pagination ul li button:active {
  background-color: #c29958;
  color: #ffffff;
}

.filter-accordian-summary div {
  margin: 0px !important;
}

.filter-accordian-summary div svg {
  color: #c29958;
  transform: rotate(179deg);
}

.filter-text-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c8c7cc;
  padding: 22px 12px 19.5px;
}

.filter-text-box .filter-text {
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  font-family: mulish-bold;
}

.filter-text-box .reset-text {
  font-size: 15px;
  line-height: 28px;
  color: #c29958;
  font-family: mulish-regular;
  text-transform: capitalize;
}

.add-cart-box-hover .hover-btns {
  height: 32px;
  padding: 0px;
  width: 100%;
  max-width: 32px;
}

.add-cart-box-hover .hover-btns:hover {
  background-color: transparent;
}

.add-cart-bg {
  background-color: #c29958;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-cart-bg:last-child {
  margin-right: 0px;
}

.show-hover .add-cart-box-hover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-hover {
  display: none;
  background: rgb(194 153 88 / 12%);
  padding: 13px 0px 12px 0px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}

.product-box:hover .product-img-box .show-hover {
  display: flex;
  justify-content: center;
}

.product-img-box {
  position: relative;
}

.filter-chekbox-main {
  padding: 22px 12px 0px;
}

.filter-chekbox-main .filter-accordian-text {
  color: #c29958;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-semibold;
}

.filter-accordian .filter-accordian-summary {
  padding: 0px;
  min-height: 40px !important;
}

.filter-accordian {
  border-bottom: 1px solid #c8c7cc;
  padding: 0px 0px 17px;
  margin-bottom: 12px;
}

.filter-accordian:last-child {
  border-bottom: 0px;
}

.filter-accordian::before {
  display: none;
}

.filter-accordian .filter-accordian-summary .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.filter-accordian .filter-accordian-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px;
}

.filter-lables span {
  color: #666666;
  font-size: 16px;
  line-height: 22px;
  font-family: open-sans-regular;
  padding: 5px 0px 5px 10px;
}

.side-filter-main {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  min-width: 255px;
  margin-bottom: 100px;
}

.filter-chekbox-main .filter-accordian {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none;
}

.hero-title-box .hero-inner-title {
  font-size: 55px;
  line-height: 75px;
  color: #ffffff;
  font-family: cambria-math;
}

.hero-title-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 245px;
  position: relative;
}

.hero-title-box span {
  z-index: 9;
  position: relative;
  padding-left: 24px;
}

.hero-title-box .hero-inner-title::after {
  content: '';
  position: absolute;
  background-image: url(../images/png/banner-yellow.png);
  height: 115px;
  width: 81px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0px;
  top: 63px;
}

/* end rings page */

.requestproduct1-sec {
  background-image: url(../images/png/requestproduct.png);
  height: 551px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.requestproduct1-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}

.thankyou-img {
  height: 65.96px;
  width: 65.88px;
  object-fit: contain;
  margin-bottom: 28.21px;
}

.requestproduct1-content .thankyou-text {
  color: #000000;
  font-size: 50px;
  line-height: 50px;
  font-family: cambria-math;
  margin: 0px 0px 14px;
}

.requestproduct1-content .thankyou-para {
  color: #000000;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-bold;
}

/* start men's jewelry page css */
.tabs {
  background-color: #f2f2f2;
  width: 100%;
}

.tabs button {
  text-transform: capitalize;
  color: #484848;
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  margin: 0px;
  padding: 13px 19px;
}

.tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #c29958;
  margin: 0px 12px 0px 0px;
  padding: 0px 6px;
}

.tabs .css-1aquho2-MuiTabs-indicator {
  background-color: transparent;
}

.filter-lables svg {
  color: #8a8a8f;
}

.pagination-box-mens-womens {
  margin: 65px 0px 39px 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabspanel .css-19kzrtu {
  padding: 40px 0px 0px 0px !important;
}

/* end men's jewelry page css */

/* start goldinfo page */
.goldinfo-sec {
  /* background-image: url(../images/png/goldinfo-banner.png); */
  height: 531px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.ringinfo-hero-sec {
  height: 531px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.goldinfo-content {
  margin: 50px 0px 57px;
}

.goldinfo-content .goldinfo-sub-text {
  font-family: mulish-bold;
  font-size: 25px;
  line-height: 32px;
  margin: 0px 0px 22px;
  color: #484848;
}

.goldinfo-content .goldinfo-para {
  font-size: 19px;
  line-height: 32px;
  color: #484848;
  font-family: mulish-regular;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-51 {
  margin-bottom: 51px !important;
}

.gold-info .gold-inner-title::after {
  content: '';
  position: absolute;
  background-image: url(../images/png/banner-yellow.png);
  height: 133px;
  width: 81px;
  background-repeat: no-repeat;
  background-size: cover;
  left: -27px;
  top: -16%;
}

.gold-info .gold-inner-title {
  font-size: 78px;
  color: #ffffff;
  font-family: cambria-math;
  position: relative;
  line-height: 107px;
}

.gold-info {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 531px !important;
  max-width: 500px;
  margin-left: auto;
}

/* end goldinfo page */

/* start uploading products page css */

.uploading-info .uploading-inner-title::after {
  content: '';
  position: absolute;
  background-image: url(../images/png/banner-yellow.png);
  height: 133px;
  width: 81px;
  background-repeat: no-repeat;
  background-size: cover;
  left: -27px;
  top: -7%;
}

.uploading-info .uploading-inner-title {
  font-size: 78px;
  color: #ffffff;
  font-family: cambria-math;
  position: relative;
  line-height: 107px;
}

.uploading-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  max-width: 667px;
  min-height: 560px !important;
}

.uploadingproducts-sec {
  background-image: url(../images/png/uploadingproducts-banner.png);
  height: 531px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* start uploading products page css */

/* start membership foem page css */
.membership-sec {
  padding: 50.5px 0px 0px;
}

.membership-bg {
  background-image: url(../images/png/membershipfoem-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.membership-title {
  color: #000000;
  font-size: 50px;
  line-height: 69px;
  font-family: cambria-math;
  margin: 0px 0px 60px;
  padding-top: 17px;
}

.membership-main {
  width: 100%;
  max-width: 559px;
  margin: auto;
}

.flex-colum-form {
  display: flex;
  justify-content: space-between;
  align-items: centers;
}

.bank-details-title {
  font-size: 27px;
  line-height: 34px;
  font-family: mulish-bold;
  color: #000000;
  margin: 15px 0px 20px;
}

.membership-formcontrol {
  width: 100%;
  max-width: 626px;
}

.membership-formcontrol1 {
  width: 100%;
  max-width: 300px;
}

.pg-main-title-box .pg-main-title {
  font-size: 50px !important;
  line-height: 50px !important;
}

.membershop-select div {
  padding: 9px 20px;
  font-family: 'mulish-semibold';
  font-size: 16px;
  line-height: 20px;
  /* color: #b8b8b8; */
  color: #8a8a8f;
  /* color: #3e3e3e; */
  text-align: left;
}

.custom-dropdown-arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  /* Adjust the positioning as needed */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  /* Decrease the size of the arrow */
  border-right: 5px solid transparent;
  /* Decrease the size of the arrow */
  border-top: 5px solid #616161;
  /* Adjust the color of the arrow */
  transform: translateY(-50%);
  font-size: 16px;
  /* Adjust the font size */
}

.membershop-select1 div {
  /* padding: 9px 20px; */
  font-family: 'mulish-semibold';
  font-size: 16px;
  line-height: 20px;
  color: #b8b8b8;
  text-align: left;
}

.flex-form-control input {
  width: 100%;
  min-width: 231px;
  padding: 13px;
}

.flex-form-control:first-child {
  margin-left: 47px;
}

.membership-input-box {
  margin: 0px 0px 20px !important;
}

.membership-submit-btn {
  margin: 0px !important;
}

.membership-formcontrol {
  position: relative;
}

.membership-formcontrol svg {
  display: none;
}

.arkgreydown-img {
  position: absolute;
  right: 18.06px;
  height: 7.5px;
  width: 15px;
  object-fit: cover;
  top: 21px;
}

/* end membership form page css */

/*  start css customer services */
.search-customer-main {
  max-width: 512px;
  margin: auto;
}

.search-customer-main .header-search-form-control input {
  background-color: #ffffff;
  border: 1.5px solid #ffffff;
  border-radius: 25px;
  height: 49px;
  padding: 0px 50px 0px 27px;
  width: 100%;
}

.search-customer-main .search-grey-box {
  position: absolute;
  right: 19.65px;
  top: 17.67px;
}

.search-icon {
  top: 7px;
}

.search-customer-main .header-search-form-control input::placeholder {
  font-family: 'mulish-semibold';
  font-size: 14px;
}

/* end css customer services */

/* start aboutus css */
.back-yellow-user .user-test-img {
  height: 149px;
  object-fit: cover;
  max-width: 150px;
}

.view-cart-empty-message {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 300px !important;
  font-size: 34px !important;
  line-height: 34px !important;
  font-family: 'cambria-math' !important;
  color: #000000 !important;
}

.back-yellow-user {
  position: absolute;
  top: 5px;
  left: 9px;
  bottom: 5px;
  box-shadow: -4px 1px 8px #00000063;
  border-radius: 50%;
}

.back-img-yellow {
  background: #c29958;
  width: 159px;
  height: 159px;
  border-radius: 50%;
}

.main-img-testimonial {
  position: relative;
  margin-right: 16.33px;
}

.textimonial-quote-top .textimonialquote-top {
  max-width: 54.67px;
  height: 41px;
}

.main-flex-text {
  align-items: center;
  display: flex;
}

.items-main-owl {
  max-width: 855px;
  margin: auto;
}

.testi-details-user .lorem-user-testi {
  font-size: 18px;
  line-height: 28px;
  font-family: 'mulish-semibold';
  color: #484848;
  margin: 11px 3px 0px 0px;
}

.testi-details-user {
  margin-left: 62.67px;
}

.textimonial-quote-bottom .bottom-quotes-testi {
  margin-left: auto;
  transform: rotate(180deg);
}

.testimonial-about-us .owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 46px;
}

.testimonial-about-us .owl-theme .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  margin: 5.5px 7px;
  background: rgb(194 153 88 / 39%);
}

.testimonial-about-us .owl-theme .owl-dots .owl-dot.active span,
.testimonial-about-us .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #c29958;
}

.text-quote-testimonial .p-text-quote {
  font-family: 'mulish-bold';
  color: #946419;
  font-size: 22px;
  text-align: end;
  line-height: 28px;
  margin-top: 12px;
}

/* end aboutus css */

/* start blog css */
.website-blogs-main {
  padding: 50px 0px 34px 0px;
}

.website-inner-details {
  padding-bottom: 21px;
}

.website-titles .how-website-text {
  color: #484848;
  text-align: center;
  font-size: 55px;
  line-height: 75px;
  font-family: 'cambria-math';
  margin-bottom: 51px;
}

.title-lorem-blog-main .title-blog-details {
  color: #484848;
  font-size: 25px;
  font-family: 'mulish-bold';
  margin-bottom: 22px;
  line-height: 32px;
}

.desc-lorem-blog .desc-blog-details {
  color: #484848;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
  margin-bottom: 30px;
}

.blogs-card .about-membership {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-bottom: 21px;
}

.blogs-card .body-about-card {
  padding: 38px 16px 33px 16px;
}

.blogs-card .inner-box-aboutus .title-card-abouts {
  font-size: 20px;
  margin: 0px 0px 9px 0px;
  line-height: 30px;
  word-wrap: break-word;
}

.blog-guidelines-title .guideline-text {
  color: #484848;
  font-size: 55px;
  font-family: 'cambria-math';
  text-align: center;
  margin-bottom: 63px;
}

.blogs-guideline-main {
  padding: 50px 0px 15px 0px;
}

.blogs-guideline-main .box-card-about-details {
  padding-bottom: 49px;
}

/* end blog css */

/* start membership page css */

.membership-title-box .become-title {
  color: #484848;
  font-size: 55px;
  line-height: 75px;
  font-family: 'cambria-math';
  text-align: center;
  margin-bottom: 25px;
}

.membership-title-box {
  margin-top: 35px;
}

.form-filling-text {
  margin-top: 20px !important;
}

.membership-content-main {
  margin: 50px 0px 69.92px;
}

.membership-content-main .become-inner-title {
  font-size: 25px;
  line-height: 32px;
  font-family: mulish-bold;
  color: #484848;
  margin: 0px 0px 35px;
}

.greyround-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin: 8px 16px 0px 0px;
}

.become-para-main {
  display: flex;
}

.become-para-main .become-para {
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
  color: #484848;
  margin: 0px 0px 22.92px;
}

.form-filing-main .form-filing-titles {
  font-size: 25px;
  line-height: 32px;
  font-family: mulish-bold;
  color: #484848;
  margin: 0px 0px 11px;
}

/* .form-filing-main {
  margin-bottom: 15px;
  display: flex;
} */

.form-filing-main .steps-box {
  margin-right: 15px;
  width: 100%;
  min-width: 90px;
}

.smalldot-img {
  height: 8px;
  width: 8px;
  object-fit: contain;
  margin: 8px 14px 0px 0px;
}

.membership-ul-main .membership-list {
  padding: 0px;
}

.membership-list .membership-item {
  padding: 0px;
}

.membership-list .membership-text {
  margin: 0px;
}

.membership-text span {
  font-size: 19px;
  line-height: 25px;
  font-family: mulish-regular;
  margin: 0px 0px 11px;
  color: #484848;
  display: flex;
}

/* .terms-text {
  margin: 55px 0px 61px 0px !important;
} */

/* end membership page css */

/* start rings info page */
.rings-info-content .ringsinfo-para {
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
  color: #484848;
}

.ringsinfo-sub-title-box .ringsinfo-sub-title {
  font-size: 25px;
  line-height: 32px;
  font-family: mulish-bold;
  color: #484848;
  margin: 51px 0px 22px;
}

.mb-67 {
  margin-bottom: 67px !important;
}

.rings-info-top-sec {
  margin: 70.5px 0px 57px;
}

.ringstop-sub-title {
  margin: 25px 0px 44px 0px !important;
}

.ringinfo-title-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 531px !important;
}

.ringsinfo-img-box .ringinfo-main {
  margin: 0px 37px 0px 0px !important;
}

.ringsinfo-img-box {
  height: 531px;
  width: 100%;
  background-image: url(../images/png/ringsinfo.png);
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
}

.ringinfo-title-box .ringinfo-inner-title::after {
  content: '';
  position: absolute;
  background-image: url(../images/png/banner-yellow.png);
  height: 133px;
  width: 81px;
  background-repeat: no-repeat;
  background-size: cover;
  left: -27px;
  top: -16%;
}

.ringinfo-title-box .ringinfo-inner-title {
  font-size: 78px;
  color: #ffffff;
  font-family: cambria-math;
  position: relative;
  line-height: 107px;
}

/* end rings info page */

/* start addinfo page(requestproduct) */
.addinfo-main-sec {
  margin: 59.5px 0px 48px;
}

.addinfo-btn-box .addinfo-save-btn {
  background-color: #c29958 !important;
}

.addinfo-text-box .addinfo-text {
  font-size: 34px;
  line-height: 34px;
  font-family: 'cambria-math';
  color: #000000;
  margin: 10px 0px 20px;
  text-align: left;
}

.addproduct-textarea-main textarea {
  min-height: 197px;
  max-width: 620px;
  width: 100% !important;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  resize: none;
  height: 100%;
  color: #8a8a8f;
  padding: 8.5px;
  font-size: 16px;
  font-family: mulish-semibold;
  outline: none;
}
 .add-product-textarea textarea{

  max-width: 700px !important;
}

.admin-addproduct-area {
  max-width: 100% !important;
}

.edit-text {
  color: #000 !important;
}

.addinfo-btn-box .btn-addinfo {
  width: 100%;
  max-width: 180px;
  height: 45px;
  color: #ffffff;
  font-family: mulish-bold;
  font-size: 20px;
  line-height: 25px;
  border-radius: 0px;
  text-transform: capitalize;
}

.addinfo-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 43px 0px 0px 0px;
}

.primary-color {
  background-color: #946419 !important;
}

.secondary-color {
  background-color: #484848 !important;
}

.border-btn {
  border: 1px solid #000000 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.edit-icon-img {
  height: 14.66px;
  width: 14.66px;
  object-fit: contain;
  margin-right: 10px;
}

.requestproduct-overlay {
  background: #03020287;
  width: 530px;
  height: 576px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0px;
}

.addinfo-img-box {
  position: relative;
}

.addinfo-img-box {
  width: 530px;
  height: 576px;
  background-size: contain;
  background-image: url(../images/png/add-info.png);
  background-repeat: no-repeat;
}

.addinfo-banner-text {
  color: #ffffff;
  font-size: 50px;
  line-height: 69px;
  font-family: 'cambria-math';
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 576px;
}

.position-relative {
  position: relative;
}

.textarea-main .addinfo-textarea {
  width: 508px;
  max-width: 559px;
  border: 1px solid #dfdfdf;
  resize: none;
  min-height: 214px;
}

.file-upload-box .file-upload-btn {
  background-color: #7b766e;
  height: 49px;
  width: 100%;
  border-radius: 0px;
  text-transform: capitalize;
  margin: 8px 0px 20px;
  box-shadow: none;
}

.file-upload-box .file-upload-btn:hover {
  background-color: #7b766e;
}

.plus-icon-img {
  height: 11px;
  width: 11px;
  object-fit: contain;
  margin-right: 13px;
}

.gold-main {
  min-height: 531px;
}

.goldinfo-banner-main {
  max-width: 525px;
  margin-left: auto;
}

/* end addinfo page(requestproduct) */

/* strat rings1 page css */
.seler-certi-sec {
  padding-bottom: 85px;
}

.seler-certi-text-box .seler-serti-text {
  color: #000000;
  font-size: 50px;
  line-height: 69px;
  font-family: 'cambria-math';
  margin: 0px 0px 58px;
}

.certi-img {
  width: 350px;
  height: 248px;
  object-fit: cover;
}

.product-desc-sec {
  margin: 40px 82px 65px 82px;
}

.certi-img-box {
  height: 248px;
  box-shadow: 0px 3px 6px #00000029;
}

.product-desc-title-box .product-desc-title {
  font-size: 27px;
  line-height: 34px;
  color: #000000;
  font-family: mulish-bold;
  margin-bottom: 16px;
}

.product-desc-content-box {
  background-color: #dfdfdf4a;
}

.product-desc-main:last-child {
  border-bottom: 0px;
}

.product-flex {
  display: flex;
}

.product-desc-left {
  width: 100%;
  max-width: 166px;
}

.product-desc-right {
  width: 100%;
  flex: 1;
}

.product-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #484848;
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 5px;
  font-family: 'mulish-semibold';
}

.product-desc-main {
  border-bottom: 1px solid #c8c7cc;
  padding: 17px 20px 13px;
}

.product-heading {
  color: #000000;
  font-family: mulish-bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.right-side-data {
  padding-left: 20px;
  height: 24px;
}

.rings1-flower-sec {
  margin: 67.5px 0px 0px;
}

.flower-text-box .flower-title {
  color: #000000;
  font-size: 33px;
  line-height: 41px;
  font-family: mulish-bold;
  margin-bottom: 4px;
}

.flower-text-box .rosegold-text {
  color: #484848;
  font-size: 19px;
  line-height: 24px;
  margin: 10px 0px;
  font-family: mulish-semibold;
}

.flower-text-box .weight-text {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-semibold;
  margin-bottom: 25px;
}

.flower-text-box .rings1-price-text {
  color: #c29958;
  font-size: 27px;
  line-height: 34px;
  font-family: mulish-bold;
  margin: 10px 0px 17px 0px;
}

.flower-text-box .description-text {
  color: #000000;
  font-size: 21px;
  line-height: 26px;
  font-family: mulish-bold;
  margin-bottom: 10px;
}

.rings-left-banner-img {
  width: 540px;
  height: 494px;
  object-fit: contain;
}

.flower-text-box .description-para {
  color: #484848;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.rings1-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.rings1-btn-box .rings1-btn:hover {
  color: #ffffff;
  background-color: #c29958;
}

.rings1-btn-box .rings1-btn {
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  height: 45px;
  border-radius: 0px;
  width: 100%;
  max-width: 260px;
  text-transform: capitalize;
}

.rings1-btn-box .customis-btn {
  color: #c29958;
  background-color: #ffffff;
  border: 2px solid #c29958;
}

.rings1-btn-box .buy-now-btn {
  color: #ffffff;
  background-color: #c29958;
}

.rings1-btn-box .rings1-btn:first-child {
  margin-right: 21px;
}

.quenty-text {
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  color: #000000;
  min-width: 40px;
}

.add-quenty-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-quenty-box .add-cart-btn {
  color: #ffffff;
  background-color: #c29958;
  width: 100%;
  max-width: 246px;
}

.rings1-images-main {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  height: 140px;
  width: 781px;
  margin-top: 35px;
}

.rings1-img-box {
  border: 1px solid #c29958;
  height: 152px;
  margin-right: 25px;
}

.rings1-images-main .rings1-img-box:last-child {
  margin-right: 0px;
}

.rings1-img {
  width: 152px;
  height: 152px;
  object-fit: contain;
}

.quenty-btn-box .qty-btn-group {
  margin-left: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
  background-color: #ffffff;
}

.qty-btn-group .btn-qty {
  padding: 0px;
  border-radius: 0px;
  height: 45px;
  border: 0px;
  color: #000;
  font-size: 18px;
  line-height: 23px;
}

.qty-btn-group .btn-qty:hover {
  border: 0px;
}

/* end rings1 page css */

/* start nevigation css for header */

.side-menu-img {
  height: 13.18px;
  width: 22.6px;
  object-fit: contain;
}

.next-arrow-img {
  height: 13px;
  object-fit: contain;
  width: 7.43px;
}

.side-menu-list .list-item-menu-btn {
  flex-direction: row-reverse;
  padding: 18.5px 24.78px 16.5px 0px;
  border-bottom: 1px solid #dfdfdf;
}

.side-menu-list .list-item-menu-btn:hover {
  background-color: transparent;
}

.side-menu-list .list-item-menu-btn span {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-family: mulish-semibold;
}


.side-menu-list .list-item-menu-btn.active span {
  /* color: #ffffff;
   */
  /* color: green;
  background-color: red;
  border-radius: 4px; */
  /* background-color: gray; */
  /* color: blue; */
  /* color: #1100f3;
   */
  color: #946419
    /* background: transparent linear-gradient(90deg, #2E60EB 0%, #2E60EB00 100%) */
    /* background: transparent linear-gradient(270deg, #ffffff1a 0%, #003d90 100%) 0% 0% no-repeat padding-box; */
}

.side-menu-main {
  width: 100%;
  min-width: 280px;
}

.side-menu-main .side-menu-list {
  padding-left: 40px;
}

.side-user-name-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.side-user-name-box .username-text {
  color: #ffffff;
  font-size: 22px;
  line-height: 30px;
  font-family: 'cambria-math';
  margin-left: 9px;
}

.side-menu-user-box {
  display: flex;
  background-color: #c29958;
  padding: 36px 24.57px 36px 40px;
  justify-content: space-between;
  align-items: center;
  overflow-y: clip !important;
}

.side-menu-user-img {
  height: 33px;
  width: 33px;
  object-fit: contain;
}

.side-menu-close-img {
  height: 13.43px;
  width: 13.43px;
  object-fit: contain;
}

.side-menu-list .side-list-item-text {
  margin: 0px;
}

.navigation-main {
  display: flex;
  align-items: center;
}

.open-sidebar button {
  padding: 0px;
  min-width: max-content;
}

.navigation-baar .nav-ul {
  display: flex;
  /* padding: 0px 0px 0px 33.5px; */
  padding: 0px 0px 0px 230.5px;
}

.navigation-baar .nav-button {
  padding: 0px;
  color: #000000;
  font-family: mulish-bold;
  font-size: 17px;
  line-height: 21px;
}

.navigation-baar .nav-button.active {
  padding: 0px;
  /* color: #1100f3;
   */
  color: #c29958;
  font-family: mulish-bold;
  font-size: 17px;
  line-height: 21px;
}

.navigation-baar .nav-items {
  width: max-content;
  padding: 15px 66px 15px 0px;
}

.navdown-img {
  width: 9.79px;
  height: 5.6px;
  object-fit: contain;
  margin-left: 5.1px;
}

.nav-items:hover .hover-nav {
  display: block;
}

.hover-nav {
  background-color: #ffffff;
  display: none;
  position: absolute;
  top: 0;
  margin-top: 51px;
  z-index: 999;
  left: -179%;
}

.nav-inner-main {
  display: flex;
  justify-content: space-between;
  border-top: 4px solid #c29958;
}

.nav-blog-left {
  background-color: #ffffff;
  /* min-width: 285px; */
  width: 100%;
}

.nav-blog-right {
  min-width: 231px;
  width: 100%;
  background-color: #f9f5ee;
  padding-bottom: 20px;
}

.nav-inner-main .nav-hover-ul {
  padding: 0px;
}

.nav-inner-main .nav-hover-li {
  padding: 0px;
  -webkit-column-break-inside: avoid;
  width: 100%;
  cursor: pointer;
}

.nav-hover-li .nav-hover-li-heading {
  color: #c29958;
  padding: 17px 0px;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-semibold;
  margin: 0px;
  position: relative;
}

.nav-hover-li-heading span {
  padding: 0px 0px 0px 15px;
  font-family: 'mulish-semibold';
}

.nav-hover-ul .nav-hover-li-heading::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #c29958;
  width: 80%;
  padding-bottom: 17px;
}

.nav-hover-li-data span {
  color: #484848;
  font-size: 16px;
  line-height: 20px;
  font-family: mulish-semibold;
  padding: 9px 15px 0px;
}

.nav-hover-li .nav-hover-li-data {
  margin: 0px;
}

.nav-hover-ul a {
  text-decoration: none !important;
  -webkit-column-break-inside: avoid;
  width: 100%;
}

.inner-data .flex-inner-ul {
  -webkit-column-count: 2;
  -webkit-column-fill: auto;
  -moz-column-count: 2;
  -moz-column-fill: auto;
  column-count: 2;
}

/* .modal-loader{
  background: #00000063;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.modal-btn-box .buttonProgress {
  width: 24px;
  height: 24px;
  top: 503px;
  left: auto;
}

.place-on-order-li::after {
  content: '';
  display: block;
  background: #000000;
  width: 2px;
  height: 21px;
  position: absolute;
  right: -35px;
  top: 0px;
  margin: auto;
}

.hover-nav-jewellery {
  left: -94% !important;
}

.nav-jewelry-one {
  width: 100%;
  min-width: 285px;
  background-color: #ffffff;
  /* padding-bottom: 20px; */
}

.nav-jewelry-second {
  width: 100%;
  min-width: 285px;
  background-color: #ffffff;
}

.nav-jewelry-third {
  width: 100%;
  min-width: 231px;
  background-color: #f9f5ee;
}

.nav-hover-ul .last::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #c29958;
  width: 100% !important;
  padding-bottom: 17px;
}

/* end nevigation css for header */

/* start my product page css */

.myproduct-sec {
  padding: 61px 0px 48px;
}

.myproduct-title-box .myproduct-title {
  color: #000000;
  font-size: 50px;
  line-height: 50px;
  font-family: cambria-math;
  margin: 0px 0px 34px 0px;
}

.primary-btn-box .primary-btn {
  font-size: 18px;
  line-height: 23px;
  font-family: mulish-semibold;
  height: 45px;
  border-radius: 0px;
  width: 100%;
  text-transform: capitalize;
  padding: 0px 55px 0px 55px;
}

.primary-btn-box .primary-btn {
  color: #ffffff !important;
  background-color: #c29958;
}

.myproduct-tabs-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dfdfdf;
  padding: 0px 5px 0px 0px;
}

.myproduct-tabs-panel .css-19kzrtu {
  padding: 40px 0px 0px 0px;
}

.primary-btn-box .add-product-btn {
  min-width: 208px;
  color: #ffffff;
  background-color: #c29958;
  width: 100%;
}

.myproduct-tabs {
  height: 55px;
}

.myproduct-tabs-inner {
  margin-top: 40px;
}

.myproduct-tabs .myproduct-tabs-lable {
  background: transparent;
  margin: 0px;
  color: #7b766e;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-bold;
  padding: 0px 55px;
  text-transform: capitalize;
  height: 55px;
}

.myproduct-tabs .css-1aquho2-MuiTabs-indicator {
  background-color: #c29958;
  height: 2px;
}

.myproduct-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #c29958 !important;
}

/* end my product page css */

/* start add product page */
.addproduct-main .addproduct-button {
  background-color: #c29958;
  width: 100%;
  max-width: 445px;
  height: 576px;
  border-radius: 0px;
  box-shadow: none;
  font-size: 40px;
  line-height: 40px;
  font-family: cambria-math;
  text-transform: capitalize;
}

.addproduct-main .addproduct-button:hover {
  background-color: #c29958;
  border-radius: 0px;
  box-shadow: none;
}

.addproduct-textarea-main .addinfo-textarea {
  width: 99.55%;
  height: 197px !important;
  resize: none;
  border-color: #dfdfdf;
}

.addproduct-sec {
  padding: 50px 0px 48px;
}

.addproduct-input-box {
  margin: 0px 2.5px 0px 0px !important;
}

.addproduct-drop-box {
  margin: 0px 5.5px 0px 0px !important;
  position: relative;
}

.black-down-img {
  position: absolute;
  right: 14.05px;
  height: 7.5px;
  width: 15px;
  object-fit: contain;
  top: 17px;
}

.black-down-img1 {
  position: absolute;
  right: 20.05px;
  height: 7.5px;
  width: 15px;
  object-fit: contain;
  top: 19px;
}

.down-produt-img {
  top: 38px !important;
}

.primary-btn-box .primary-btn:hover {
  background-color: #c29958;
}

.next-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-btn-box-remove {
  justify-content: flex-end;
}

.next-btn-box-add {
  margin-top: -94px;
}

.next-back-btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



.input-box .form-control input::placeholder {
  color: #000;
}

/* .form-control input::placeholder {
  color: #c8c7cc;
} */

.next-btn-box .next-btn {
  max-width: 304px;
  margin-right: 50px;
}

.save-btn-profile-box .save-btn-profile {
  max-width: 283px;
  margin-right: 50px;
}

.next-btn-box .next-btn:last-child {
  margin-right: 0px;
}

.save-btn-profile-box .save-btn-profile:last-child {
  margin-right: 0px;
}

/* .add-product-left .add-upload-bg{
  background-color: #C29958;
  height: 576px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.plus-sign {
  height: 47px;
  width: 47px;
  font-size: 47px;
}

.hover-img {
  height: 17px;
  width: 15px;
  object-fit: cover;
}

/* .add-upload-bg .add-upload-btn{
  font-size: 40px;
  line-height: 55px;
  color: #ffffff;
  background-color: transparent;
  text-transform: capitalize;
  font-family: cambria-math;
  box-shadow: none;
  height: 576px;
  width: 100%;
} */
/* .add-upload-bg .add-upload-btn:hover{
  background-color: transparent;
  box-shadow: none;
} */
.add-product-left .add-upload-bg {
  background-color: #c29958;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-product-left .add-upload-order-bg {
  background-color: #c29958;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-sign {
  height: 47px;
  width: 47px;
  font-size: 47px;
}

.hover-img {
  height: 20px;
  width: 22px;
  object-fit: cover;
}

.add-upload-bg .add-upload-btn {
  font-size: 40px;
  line-height: 55px;
  color: #ffffff;
  background-color: transparent;
  text-transform: capitalize;
  font-family: cambria-math;
  box-shadow: none;
  height: 80px;
  width: 100%;
}

.add-upload-bg .add-upload-btn:hover {
  background-color: transparent;
  box-shadow: none;
}

.add-upload-order-bg .add-upload-order-btn {
  font-size: 35px;
  line-height: 55px;
  color: #ffffff;
  background-color: transparent;
  text-transform: capitalize;
  font-family: cambria-math;
  box-shadow: none;
  height: 80px;
  width: 100%;
}

.add-upload-order-bg .add-upload-order-btn:hover {
  background-color: transparent;
  box-shadow: none;
}

.addproduct-1-sec {
  padding: 59.5px 0px 47px 0px;
}

.addproduct-1-input-box {
  margin: 0px 2.5px 18px 0px !important;
}

.product-dimension {
  margin: 5px 0px 0px 0px !important;
}

.refrence-product-input {
  position: relative;
}

.addproduct-mm-text {
  position: absolute;
  right: 15px;
  top: 39px;
  color: #7b766e;
  font-size: 14px;
  font-family: mulish-semibold;
  line-height: 18px;
}

.form-validation-text {
  margin: 5px 0px 0px 0px !important;
  color: #dfdfdf;
  font-size: 14px;
  font-family: mulish-semibold;
  line-height: 18px;
}

.addproduct-1-textarea-main .add-1-info-textarea {
  height: 129px !important;
}

.addinfo-text-box .product-dimension {
  margin: 7px 0px 24px 0px !important;
}

.addinfo-text-box-1 .addinfo-text-1 {
  font-size: 40px;
  line-height: 50px;
  /* font-family: 'cambria-math'; */
  color: #000000;
  margin: 0px 0px 39px;
}

.next-back-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-btn-1 {
  max-width: 304px;
  margin-right: 50px;
  font-size: 18px;
  line-height: 23px;
  /* font-family: mulish-semibold; */
  height: 45px;
  border-radius: 0px;
  width: 100%;
  text-transform: none !important;
  padding: 0px 55px 0px 55px;
  color: #ffffff;
  /* background-color: #C29958; */
}

.back-btn-1 {
  max-width: 150px;
  margin-right: 50px;
  font-size: 18px;
  line-height: 23px;
  /* font-family: mulish-semibold; */
  height: 45px;
  border-radius: 0px;
  width: 100%;
  text-transform: none !important;
  padding: 0px 55px 0px 55px;
  color: #ffffff;
  /* background-color: #C29958; */
}

.product-dimension-1 {
  margin: 5px 0px 39px 0px !important;
}

.add-product-addmore-remove-btns {
  margin: 15px 0px 25px 0px;
}

/* end add product page */

/* start details page css */
.comment-sec {
  padding: 0px 0px 62px 0px;
}

.comment-main {
  display: flex;
  background-color: rgb(223 223 223 / 29%);
  padding: 22px 0px 17px 16px;
  margin: 0px 0px 15px 0px;
}

.comment-img-box {
  margin-right: 26px;
}

.comment-img {
  height: 89px;
  width: 89px;
  object-fit: cover;
}

.comment-content-box .comment-user-name {
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  color: #000000;
  margin: 0px 0px 5px 0px;
}

.comment-content-box .comment-user-para {
  font-size: 19px;
  line-height: 24px;
  font-family: mulish-semibold;
  color: #484848;
  margin: 0px 0px 13px 0px;
}

.comment-content-box .comment-user-date-time {
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  color: #484848;
}

.comment-title-box .comment-text {
  font-size: 50px;
  line-height: 50px;
  margin: 0px 0px 46px 0px;
  font-family: cambria-math;
}

.comment-main:last-child {
  margin-bottom: 0px;
}

.seller-details-custome-product-view {
  width: 100%;
  margin: auto;
  max-width: 1110px;
}

.seller-details-custome-product-view-main-div {
  margin-bottom: 50px;
}

.custome-product-accept-button {
  background-color: #d7bd94 !important;
}

.view-custome-order-place-button {
  padding: 0px !important;
}

/* end details page css */

/* start precious stone page */
.precious-stone-sec {
  padding: 61.5px 0px 0px;
}

.precious-pagination {
  margin: 75px 0px 67px !important;
}

/* end precious stone page */

/* start my order page */

.myorder-sec {
  padding: 61px 0px 45px;
}

.mo-tabs-main {
  background-color: #dfdfdf;
  margin: 0px 0px 20px;
}

.mo-tabs-content .css-19kzrtu {
  padding: 0px;
}

.myorder-tabs-lable {
  padding: 0px !important;
}

.mo-tabs-main .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #c29958 !important;
  background: none !important;
}

.mo-tabs-main .mo-tabs-lable {
  margin: 0px;
  background: transparent;
  padding: 17px 0px;
  color: #7b766e;
  width: 100%;
  max-width: 555px;
  text-transform: capitalize;
  font-family: 'mulish-bold';
  font-size: 17px;
  line-height: 21px;
}

.mo-tabs-main .css-1aquho2-MuiTabs-indicator {
  background-color: #c29958 !important;
  left: 0px;
  width: 100%;
  max-width: 555px;
}

.mo-table-main .mo-tablehead {
  background-color: #f9f0e3;
}

.mo-table-main .mo-th {
  padding: 11px 20px;
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  border-bottom: 0px;
}

.mo-table-main .mo-th {
  padding: 11px 20px;
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  border-bottom: 0px;
}

.mo-table-main .mo-th:nth-child(1) {
  width: 20%;
}

.mo-table-main .mo-th:nth-child(2) {
  width: 20%;
}

.mo-table-main .mo-th:nth-child(3) {
  width: 20%;
}

.mo-table-main .mo-th:nth-child(4) {
  width: 20%;
}

.mo-table-main .mo-th:nth-child(5) {
  width: 20%;
}

.mo-table-main .mo-th:nth-child(6) {
  width: 20%;
}

.new-mo-table .custom-th:nth-child(7) {
  width: 20%;
}

.mo-table-main .mo-td {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  padding: 11px 20px;
  border-bottom: 0px;
}

.mo-table-main .mo-td:nth-child(1) {
  width: 10%;
}

.mo-table-main .mo-td:nth-child(2) {
  width: 15%;
}

.mo-table-main .mo-td:nth-child(3) {
  width: 18%;
}

.mo-table-main .mo-td:nth-child(4) {
  width: 10%;
}

.mo-table-main .mo-td:nth-child(5) {
  width: 13%;
}

.mo-table-main .mo-td:nth-child(6) {
  width: 20%;
}

.mo-table-main .custom-td {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  padding: 11px;
  border-bottom: 0px;
}

.custome-edit-btn-box .custome-edit-btn,
.custome-edit-btn-box .custome-submit-btn {
  padding: 0px 30px 0px 30px;
}

.mo-table-main tr:nth-child(1) .custom-td {
  padding-top: 29px;
}

.mo-table-main .custom-td:nth-child(1) {
  width: 10%;
}

.mo-table-main .custom-td:nth-child(2) {
  width: 15%;
}

.mo-table-main .custom-td:nth-child(3) {
  width: 18%;
}

.mo-table-main .custom-td:nth-child(4) {
  width: 10%;
}

.mo-table-main .custom-td:nth-child(5) {
  width: 13%;
}

.mo-table-main .custom-td:nth-child(6) {
  width: 20%;
}

.mo-table-main .custom-th {
  padding: 11px 20px;
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  border-bottom: 0px;
}

.mo-table-main .custom-th:nth-child(1) {
  width: 12%;
}

.mo-table-main .custom-th:nth-child(2) {
  width: 19%;
}

.mo-table-main .custom-th:nth-child(3) {
  width: 29%;
}

.mo-table-main .custom-th:nth-child(4) {
  width: 4%;
}

.mo-table-main .custom-th:nth-child(5) {
  width: 27%;
}

.mo-table-main .custom-th:nth-child(6) {
  width: 10%;
}

.mo-table-main .custom-th:last-child {
  min-width: 193px;
}

.mo-table-main .td-recieved {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  padding: 11px 20px;
  border-bottom: 0px;
}

.mo-table-main tr:nth-child(1) .td-recieved {
  padding-top: 29px;
}

.mo-table-main .td-recieved:nth-child(1) {
  width: 20%;
}

.mo-table-main .td-recieved:nth-child(2) {
  width: 20%;
}

.mo-table-main .td-recieved:nth-child(3) {
  width: 20%;
}

.mo-table-main .td-recieved:nth-child(4) {
  width: 20%;
}

.mo-table-main .td-recieved:nth-child(5) {
  width: 20%;
}

.mo-table-main .th-recieved {
  padding: 11px 20px;
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  border-bottom: 0px;
}

.mo-table-main .th-recieved:nth-child(1) {
  width: 20%;
}

.mo-table-main .th-recieved:nth-child(2) {
  width: 20%;
}

.mo-table-main .th-recieved:nth-child(3) {
  width: 20%;
}

.mo-table-main .th-recieved:nth-child(4) {
  width: 20%;
}

.mo-table-main .th-recieved:nth-child(5) {
  width: 20%;
}

.mo-form-control-drop {
  background-color: #ffffff;
}

.mo-table-main .success-text {
  color: #198f0b !important;
  font-family: mulish-semibold !important;
}

.mo-table-main .danger-text {
  color: #ff0303 !important;
  font-family: mulish-semibold !important;
}

.mo-table-main .warning-text {
  font-family: mulish-semibold !important;
  color: #ff6a28 !important;
}

.custom-tabs-main {
  margin: 0px 0px 38px;
}

.myorder-view-btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.myorder-view-btn-box .myorder-view-btn {
  max-width: 213px;
  background-color: #c29958;
}

.mo-table-main .mo-table-container {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 0px;
}

.mo-table-main .mo-table-body {
  padding: 10px;
}

.body-bg {
  background-color: #f6f6f6;
}

.mo-table-body tr:first-child .myorder-view-btn {
  margin-top: 15px;
}

.mo-table-body tr:last-child .myorder-view-btn {
  margin-bottom: 20px;
}

.productr-orderproduct-data {
  margin: 10px 0px;
  padding: 11px 17px;
  color: #000000;
  font-size: 16px;
  font-family: mulish-bold;
}

/* end my order page */

/* start custome product request raised page */
.custome-edit-btn-box {
  display: flex;
}

.custome-edit-btn-box .custome-edit-btn {
  border: 1px solid #c29958;
  max-width: 102px;
  background-color: transparent;
  color: #c29958 !important;
  margin-right: 10px;
}

.custome-edit-btn-box .custome-submit-btn {
  max-width: 102px;
}

.custome-edit-btn-box .custome-edit-btn:hover {
  background-color: transparent;
  color: #c29958;
}

.custome-img {
  height: 112px;
  width: 112px;
  object-fit: contain;
}

.custome-making-charge {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customproduct-control input {
  width: 145px;
}

.custome-making-charge h2 {
  margin: 0px;
}

.custome-product-modal {
  overflow: auto;
  min-height: 500px;
  padding: 48px 40px 24px;
}

.custome-product-modal div+div+.MuiBox-root {
  max-height: calc(100vh - 100px);
  overflow: auto;
  top: 5%;
  transform: none;
  left: calc(50% - 245px);
  border-radius: 11px;
  border: 0px;
  outline: none;
  box-shadow: none;
}

.custom-modal-heading-text {
  font-size: 25px !important;
  line-height: 30px !important;
  font-family: 'cambria-math';
  color: #000000;
  margin: 10px 0px 20px;
  text-align: left;
}

.custom-product-modal-btn {
  color: #ffffff !important;
  background-color: #c29958 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  font-family: mulish-semibold !important;
  height: 45px !important;
  border-radius: 0px !important;
  width: 100% !important;
  text-transform: capitalize !important;
  padding: 0px 55px 0px 55px !important;
}

/* end custome product request raised page */

/* start order place view page */
.order-place-sec {
  padding: 61px 0px 57px;
}

.order-place-content-main {
  background-color: #ffffff;
  padding: 31px 16px;
}

.order-content-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-inner-main {
  display: flex;
  margin-bottom: 10px;
  align-items: baseline;
  align-items: center;
  min-height: 25px;
}

.order-inner-main .order-sub-title {
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  color: #000000;
}

.order-inner-main .order-sub-data {
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  color: #484848;
  padding-left: 10px;
}

.deliver-address-box {
  background-color: #f9f0e3;
  padding: 11px 14px;
  margin: 10px 0px 0px;
}

.deliver-address-box .deliver-address-text {
  color: #000000;
  font-family: mulish-bold;
  font-size: 20px;
  line-height: 25px;
}

.deliver-address-para-box .deliver-address-para {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  margin: 13px 0px 21px;
  padding-left: 29px;
  max-width: 780px;
}

.po-accordian .po-acc-summary {
  background-color: #f9f0e3;
}

.po-acc-summary .po-sub-title {
  font-size: 20px;
  line-height: 25px;
  font-family: 'mulish-bold';
  color: #000000;
}

.po-accordian .po-acc-details {
  background-color: #f6f6f6;
  margin: 0px 0px 10px 0px;
  border-top: 0px;
  padding: 0px 0px 0px 10px;
}

.po-accordian .po-acc-details:last-child {
  margin-bottom: 0px;
}

/* 
.po-accordian .po-acc-details:first-child {
  margin-bottom: 15px;
} */

.order-content-first {
  max-width: 369px;
  width: 100%;
}

.order-content-second {
  max-width: 278px;
  width: 100%;
}

.po-accordian .po-acc-details {
  box-shadow: none;
}

.order-content-third {
  max-width: 324px;
  width: 100%;
}

.accordian-order-content-main {
  display: flex;
  /* width: 100%; */
  padding: 0px 0px 20px 20px;
  /* flex-wrap: wrap; */
}

.po-return-btn-box {
  display: flex;
  justify-content: flex-end;
}

.po-acc-summary div {
  margin: 0px;
}

.po-return-btn-box .po-return-btn {
  max-width: 102px;
  border: 1px solid #c29958;
  margin-right: 20px;
}

.po-return-btn-box .po-return-btn:hover {
  /* background: #ffffff; */
  color: #c29958;
  border: 1px solid #c29958;
}

.po-summary-title-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.po-dropdown svg {
  display: none;
}

.po-dropdown .po-form-control {
  min-width: 196px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  height: 40px;
}

.po-dropdown .po-form-control input {
  padding: 10px;
}

.po-dropdown .po-select {
  border-radius: 0px;
}

.po-dropdown .po-select .MuiSelect-select {
  padding: 10px;
  font-size: 14px;
  font-family: 'mulish-semibold';
}

.po-dropdown fieldset {
  border-radius: 0px;
  border: none;
}

.order-place-accordian .po-accordian {
  border: 0px;
  margin-bottom: 10px;
}

.or-content-box {
  margin: 21px 0px 11px;
}

.order-item-status-view-order {
  margin: 5px 0px 0px 15px !important;
}

.or-address-para {
  max-width: 219px;
}

.or-track-btn-box .or-track-btn {
  background-color: #c29958;
  color: #ffffff;
  max-width: 102px;
  margin-right: 20px;
  width: 100%;
}

.primary-text-color {
  color: #c29958 !important;
}

.order-rate-main {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

/* end order place view page */

/* start my cart page */
.mycart-sec {
  padding: 61px 0px 48px 0px;
}

.mycart-delete-button-box .mycart-delete-button {
  display: flex;
  margin: auto;
}

.trash-img {
  width: 17.11px;
  height: 22px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.mycart-quenty-btn .mycart-btn-group {
  margin-left: 0px;
}

.mycart-table-main .th-mycart {
  padding: 11px 30px;
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  border-bottom: 0px;
}

.mo-table-main .th-mycart:nth-child(1) {
  width: 20%;
}

.mo-table-main .th-mycart:nth-child(2) {
  width: 40%;
}

.mo-table-main .th-mycart:nth-child(3) {
  width: 20%;
}

.mo-table-main .th-mycart:nth-child(4) {
  width: 20%;
}

.mo-table-main .th-mycart:nth-child(5) {
  width: 20%;
}

.mo-table-main .th-mycart:nth-child(6) {
  width: 20%;
  text-align: center;
  padding-right: 0px;
}

.mycart-table-main .td-mycart {
  color: #484848;
  font-size: 17px;
  line-height: 21px;
  font-family: mulish-regular;
  padding: 5px 30px;
  border-bottom: 0px;
}

.mo-table-main .td-mycart:nth-child(1) {
  width: 20%;
  padding-top: 16px;
}

.mo-table-main .td-mycart:nth-child(2) {
  width: 40%;
  padding-bottom: 0px;
}

.mo-table-main .td-mycart:nth-child(3) {
  width: 20%;
}

.mo-table-main .td-mycart:nth-child(4) {
  width: 20%;
}

.mo-table-main .td-mycart:nth-child(5) {
  width: 20%;
}

.mo-table-main .td-mycart:nth-child(6) {
  width: 20%;
  padding-right: 0px;
}

.mycart-table-body {
  background-color: #f6f6f6;
}

.cart-total-box {
  border-top: 3px solid #c29958;
  width: 100%;
  min-width: 306px;
}

.cart-total-box .cart-total-title {
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-family: mulish-bold;
  margin: 20.3px 0px 17px 0px;
}

.cart-total-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13.63px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 13.65px;
}

.cart-total-content .cart-total-main:last-child {
  border-bottom: 0px;
}

.cart-total-main .cart-total-text {
  color: #484848;
  line-height: 21px;
  font-size: 17px;
  font-family: mulish-regular;
}

.checkout-btn-box {
  margin-top: 29px;
}

/* start my cart page */

/* start payment method page css */
.dropdown-select {
  min-width: 172px;
  border-radius: 35px;
  background-color: #ffffff;
  height: 50px;
  width: 100%;
}

.dropdown-main .MuiInputBase-input {
  padding: 15px 17px;
  color: #b8b8b8;
  font-size: 14px;
  line-height: 18px;
  font-family: mulish-regular;
  text-align: left;
}

.blackdown-img-box {
  position: absolute;
  right: 13px;
  top: 17px;
}

.dropdown-main .MuiSvgIcon-root {
  display: none;
}

.blackdown-img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.payment-col-1 {
  width: 100%;
  min-width: 745px;
  flex: 0 0 745px;
  margin-right: 30px;
}

.payment-col-2 {
  flex: 1;
}

.payment-main {
  display: flex;
}

.flex-dropdown {
  display: flex;
}

.metro-img {
  height: 23.97px;
  width: 23.97px;
  object-fit: contain;
}

.flex-inner-grid {
  text-align: start;
  display: flex;
  align-items: center;
  position: relative;
}

.payment-dropdown {
  margin-right: 13px;
}

.payment-form-content {
  padding: 32px 54px 12px;
  background-color: #f2f2f2;
  border-radius: 28px;
  margin: 30px 0px 37px;
}

.for-details-main {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  padding: 22px 13px 46px;
}

.for-details-main .for-details-title {
  color: #000000;
  font-size: 22px;
  font-family: 'Barlow-Bold';
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 21.5px;
  margin-bottom: 14.5px;
}

.btn-confirm-pay {
  width: 100%;
  max-height: 55px;
}

.total-amount-box {
  background-color: #f9f9f9;
  border-radius: 9px;
  padding: 19px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0px 37px;
}

.for-details-main .total-amount-text {
  color: #000000;
  font-size: 19px;
  line-height: 22px;
  font-family: 'Ubuntu-Bold';
}

.amount-data-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.for-details-main .amount-text {
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  font-family: 'Ubuntu-Regular';
}

.payment-card-box {
  background: #ffffff;
  padding: 13.5px 23px;
  border: 1px solid #dfdfdf;
}

.payment-flex {
  display: flex;
}

.payment-radio-box {
  display: flex;
  align-items: center;
}

.expit-security-main {
  display: flex;
}

.security-flex {
  margin-left: 6px;
}

.radio-text-box .radio-text {
  color: #131416;
  font-size: 19px;
  line-height: 24px;
  font-family: mulish-bold;
}

.payment-image-box {
  margin-left: 13px;
}

.payment-radio-lable span {
  padding: 0px 5px 0px 0px;
}

.paypal-img {
  height: 36px;
  width: 90px;
  object-fit: contain;
}

.paypal-image-box {
  margin-left: 29px;
}

.paypal-image-box {
  height: 36px;
  width: 90px;
  object-fit: contain;
}

.page-title-box {
  padding: 46px 0px 38px;
}

.visa-img {
  height: 40px;
  width: 100%;
  object-fit: contain;
}

.payment-cart-total-box {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  border-top: 0px !important;
  padding: 15px 22px 22px 22px;
  width: auto;
  min-width: initial;
}

.exp-input-lable .input-lable {
  font-size: 14px;
  line-height: 18px;
  color: #3e3e3e;
  font-family: mulish-semibold;
  margin: 0px 0px 6px;
}

.payment-form-control {
  background-color: #ffffff;
}

.payment-sec {
  padding: 61px 0px 54px;
}

.payment-radio-lable .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #c29958;
}

.questionmark-img {
  height: 23.97px;
  width: 23.97px;
  object-fit: contain;
  position: absolute;
  top: 36px;
  right: -31px;
}

.mobile-menu-main {
  display: none;
}

.sec-antic-title-mobile {
  display: none;
}

.right-btn-main {
  display: flex;
  /* justify-content: flex-end; */
}

.right-btn-main .right-btns {
  margin: 0px 10px 0px 0px;
}

.text-area-control-main .text-area-control1 {
  /* max-width: 536px; */
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f;
  font-family: mulish-semibold;
  position: relative;
  margin-top: 15px;
}

.text-area-control-main .text-area-control3 {
  /* max-width: 536px; */
  width: 300px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f;
  font-family: mulish-semibold;
  position: relative;
  margin-top: 15px;
}

.text-area-control-main1 .text-area-control2 {
  /* max-width: 536px; */
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f;
  font-family: mulish-semibold;
  margin-top: 15px;
}

.text-area-control-main textarea {
  /* width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff; */
  color: #8a8a8f;
  font-family: mulish-semibold;
  /* position: relative;
  margin-top: 15px; */
}

.text-area-control1:focus {
  /* max-width: 536px; */
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f;
  font-family: mulish-semibold;
  position: relative;
  margin-top: 15px;
  outline-color: #1565c0 !important;
}

.text-area-control3:focus {
  /* max-width: 536px; */
  width: 300px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #8a8a8f;
  font-family: mulish-semibold;
  position: relative;
  margin-top: 15px;
  outline-color: #1565c0 !important;
}

.text-area-control-admin {
  padding-top: 12.5px !important;
  padding-left: 12.5px !important;
  padding-right: 12.5px !important;
  font-size: 16px !important;
  color: #8a8a8f;
  border: 1px solid #dfdfdf;
}

.main-payment-table {
  width: 100%;
  max-width: 1095px;
  margin: auto;
}

/* end payment method page */


.catalogue-details-bulk-addtocart {
  width: 100%;
  max-width: 241px;
  display: flex;
  margin: 10px 0px 10px 9px;
}

.delete-product-text {
  color: #484848;
  font-size: 19px !important;
  line-height: 32px !important;
  font-family: mulish-regular !important;
  margin-bottom: 30px !important;
}

.delete-product-buttons {
  display: flex;
  width: 100%;
  max-width: 280px;
  justify-content: space-between;
  margin: auto;
}

/* .MuiTooltip-tooltip {
  max-width: 100% !important;
  margin-top: 3px !important ;
  font-family: mulish-regular !important;
} */

.catalogues-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.catalogues-row .sec-main-title {
  margin-bottom: 0px;
}

.membership-sec-modal {
  padding: 40px !important;
  box-shadow: none !important;
  border-radius: 11px !important;
  border: 0px !important;
  outline: none !important;
}

.myprofile-change-password-heading {
  font-size: 31px !important;
  line-height: 50px !important;
  color: #000000 !important;
  font-family: cambria-math !important;
  margin: 38px 0px 25px !important;
}

.save-btn-profile-box {
  margin: 25px 0px;
}

.my-profile-tabs-lable {
  margin: 0px !important;
  background: transparent !important;
  padding: 17px 0px !important;
  color: #7b766e !important;
  width: 100% !important;
  max-width: 185px !important;
  text-transform: capitalize !important;
  font-family: 'mulish-bold' !important;
  font-size: 17px !important;
  line-height: 21px !important;
}

.my-profile-product-count-text {
  color: #000000 !important;
  font-family: mulish-bold !important;
  font-size: 20px !important;
  line-height: 25px !important;
  margin-bottom: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.my-profile-product-count {
  color: #484848 !important;
  font-size: 19px !important;
  line-height: 26px !important;
  margin-bottom: 5px !important;
  font-family: 'mulish-semibold' !important;
}

.my-profile-product-main {
  display: flex;
}

.my-profile-products-right-side {
  margin-left: 20px !important;
}

.my-profile-products-end-colun {
  margin-left: 20px !important;
}

.my-order-products-order-message {
  margin: 0px 0px 20px 20px;
  color: #FF0303;
  font-size: 15px;
  line-height: 21px;
  font-family: mulish-semibold !important;
}

.return-order-products-order-message {
  margin: 20px 0px 20px 20px;
  color: #FF0303;
  font-size: 15px;
  line-height: 21px;
  font-family: mulish-semibold !important;
}

.mj-flex {
  display: flex !important;
  flex-wrap: wrap;
  gap: 17px !important;
}

.mj-ke-flex {
  flex-wrap: wrap;
  gap: 17px !important;

}

.catalogue-details-bulk-addtocart {
  width: 100%;
  max-width: 270.5px;
  display: flex;
  margin: 10px 0px 10px 9px;
  margin-left: auto;
}

.delete-product-text {
  color: #484848;
  font-size: 19px !important;
  line-height: 32px !important;
  font-family: mulish-regular !important;
  margin-bottom: 30px !important;
}

.delete-product-buttons {
  display: flex;
  width: 100%;
  max-width: 280px;
  justify-content: space-between;
  margin: auto;
}

.MuiTooltip-tooltip {
  max-width: 180px !important;
  margin-top: 3px !important;
  font-family: mulish-regular !important;
}

.catalogues-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.catalogues-row .sec-main-title {
  margin-bottom: 0px;
}

.membership-sec-modal {
  padding: 40px !important;
  box-shadow: none !important;
  border-radius: 11px !important;
  border: 0px !important;
  outline: none !important;
}

.member-form-control .MuiInputBase-input {
  padding: 13px;
}

.catalogues-details-selectall-button {
  text-transform: capitalize !important;
  font-family: mulish-semibold !important;
  font-size: 17px !important;
  line-height: 21px !important;
  color: #c29958 !important;
}

.catalogues-details-select-bulk-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1048px !important;

}

.catalogues-details-bulk-section {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  /* width: 100%;
  max-width: 900px; */
}

.catalogues-details-ramdom-product-text {
  font-size: 17px !important;
  line-height: 21px !important;
  font-family: mulish-semibold !important;
  color: #484848 !important;

}

.catalogues-details-input-lable {
  font-size: 13.5px !important;
  line-height: 21px !important;
  font-family: mulish-semibold !important;
  color: #484848 !important;
  margin: 1px 12px 0px 12px !important;
}

.catalogues-details-bulk-btn {
  height: 39px !important;
  margin-left: 12px !important;
}

.catalogues-details-errors-messages {
  margin-left: 355px;
  margin-bottom: 45px;
}

.catalogues-details-errors-div {
  display: flex;
  width: 548px;
  justify-content: space-between;
}

.catalogues-details-error-input-lable {
  font-size: 14px !important;
  line-height: 18px !important;
  color: #d32f2f !important;
  font-family: mulish-semibold !important;
  /* margin: 6px 0px 0px 6px !important; */
}

.reject-reason-details-page {
  color: #FF0303 !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.product-img-box-set {
  width: 200px;
}

.addproduct-textarea-main .textarea-custm {
  max-width: 1090px;
  width: 100%;
  min-height: 45px;
}

.description-para-Pending {
  color: #FF6A28;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
}

.description-para-Approved {
  color: #198F0B;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
}

.description-para-Rejected {
  color: #FF0303;
  font-size: 19px;
  line-height: 32px;
  font-family: mulish-regular;
}

.form-control .Mui-error {
  margin-left: 0px;
}

.input-box * {
  filter: blur(0) !important;
}

/* =============================================================================================================
============================================================================================================== */
/* =============================================================================================================
============================================================================================================== */
/* =============================================================================================================
============================================================================================================== */

@media screen and (min-width: 1536px) {}

@media screen and (min-width: 1200px) and (max-width: 1535px) {
  
}

@media screen and (min-width: 900px) and (max-width: 1199px) {}

/* =============================================================================================================
============================================================================================================== */
@media screen and (min-width: 600px) and (max-width: 899px) {
  .payment-col-1 {
    width: 100%;
    min-width: 464px;
    flex: 0 0 464px;
  }

  .payment-main {
    flex-direction: column;
  }

  .po-return-btn-box .po-return-btn {
    margin-right: 0px;
  }

  .or-track-btn-box .or-track-btn {
    margin-right: 0px;
  }

  .mo-tabs-main .mo-tabs-lable {
    max-width: 364px;
  }

  .addinfo-text-box .addinfo-text {
    font-size: 22px;
    line-height: 22px;
    margin: 0px 0px 25px;
  }

  .myorder-view-btn-box .myorder-view-btn {
    max-width: 120px;
  }

  .mo-table-main .th-recieved {
    padding: 11px 9px;
    font-size: 17px;
    line-height: 18px;
    border-bottom: 0px;
  }

  .membership-title-box .become-title {
    font-size: 35px;
    line-height: 21px;
    margin-bottom: 35px;
  }

  .membership-content-main .become-inner-title {
    font-size: 20px;
    line-height: 29px;
  }

  .form-filing-main .form-filing-titles {
    font-size: 20px;
    line-height: 29px;
  }

  .form-filing-main {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .terms-text {
    margin: 30px 0px 30px 0px !important;
  }

  .membership-content-main {
    margin: 30px 30px;
  }

  .hero-title-box .hero-inner-title {
    font-size: 39px;
    line-height: 34px;
  }

  .ringsinfo-sub-title-box .ringsinfo-sub-title {
    font-size: 20px;
    line-height: 27px;
  }

  .ringstop-sub-title {
    margin: 25px 0px 17px 0px !important;
  }

  .faq-title-main .faq-typo-custom {
    line-height: 72px;
    font-size: 50px;
  }

  .title-inner-help .how-help-text {
    font-size: 46px;
    line-height: 50px;
    margin-bottom: 16px;
  }

  .rings-info-top-sec {
    margin: 50px 0px 30px;
  }

  .upper-content-services .title-typo-services {
    font-size: 20px;
    line-height: 25px;
  }

  .title-lorem-blog-main .title-blog-details {
    font-size: 20px;
    line-height: 25px;
  }

  .website-titles .how-website-text {
    font-size: 38px;
    line-height: 21px;
    margin-bottom: 28px;
  }

  .website-inner-details {
    padding-bottom: 0px;
  }

  .testimonial-titles-main .testimonial-title,
  .ourteam-titles-main .team-title {
    font-size: 35px;
    line-height: 20px;
  }

  .product-img {
    max-width: 368px;
    height: 368px;
  }

  .top-deals-details-box {
    right: 0px;
  }

  .antic-jewelry-last-box {
    margin-top: 8px;
  }

  .trending-sec {
    padding: 50px 0px;
  }

  .antic-jewelry-sec {
    padding: 50px 0px;
  }

  .precious-sec {
    padding: 50px 0px;
  }

  .catalogues-sec {
    padding: 50px 0px;
  }

  .top-deals-sec {
    padding: 0px 0px 0px;
  }

  .sec-main-title {
    font-size: 43px;
    line-height: 15px;
    margin: 0px 0px 30px;
  }

  .faq-question-main {
    padding: 50px 0px;
  }

  .requestproduct1-content .thankyou-text {
    font-size: 41px;
    line-height: 33px;
  }

  .goldinfo-content .goldinfo-sub-text {
    font-size: 22px;
    line-height: 29px;
  }

  .goldinfo-content .goldinfo-para {
    font-size: 17px;
    line-height: 25px;
  }

  .footer-sec {
    padding: 50px 25px 22px;
  }

  .uploading-info .uploading-inner-title {
    font-size: 47px;
    line-height: 58px;
  }

  .membership-title {
    font-size: 37px;
    line-height: 36px;
    margin: 0px 0px 28px;
  }

  .bank-details-title {
    font-size: 24px;
    line-height: 30px;
  }

  .about-set-details .about-sets-title {
    font-size: 40px;
    line-height: 38px;
    margin-bottom: 9px;
  }

  .about-uslorem-main {
    padding: 50px 0px 50px 0px;
  }

  .why-choose-main {
    padding: 50px 0px 50px 0px;
  }

  .titile-inside-why .us-inner-why-chose {
    font-size: 36px;
    line-height: 28px;
    margin-bottom: 17px;
  }

  .details-sets-us-text {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 20px;
  }

  .team-img-main .img-team {
    height: 175px;
    width: 175px;
  }

  .blog-guidelines-title .guideline-text {
    font-size: 35px;
    margin-bottom: 36px;
  }

  .textarea-main .addinfo-textarea {
    width: 100%;
    max-width: 100%;
    min-height: 150px;
  }

  .rings-left-banner-img {
    width: 350px;
    height: 350px;
  }

  .rings1-img-box {
    border: 1px solid #c29958;
    height: 147px;
    margin: 20px 20px 0px 0px;
  }

  .myproduct-tabs .myproduct-tabs-lable {
    padding: 0px 50px;
  }

  .primary-btn-box .add-product-btn {
    max-width: max-content;
    min-width: max-content;
    padding: 0px 20px;
  }

  .myproduct-title-box .myproduct-title {
    font-size: 30px;
    line-height: 27px;
  }

  .comment-title-box .comment-text {
    font-size: 36px;
    line-height: 26px;
  }

  .seler-certi-text-box .seler-serti-text {
    font-size: 32px;
    line-height: 35px;
    margin: 0px 0px 25px;
  }

  .seler-certi-sec {
    padding-bottom: 46px;
  }

  .mo-form-control-drop {
    max-width: 348px;
  }

  .mo-table-main .custom-th {
    font-size: 17px;
    line-height: 19px;
  }

  .mo-table-main .mo-th:nth-child(1) {
    width: 25%;
  }

  .mo-table-main .mo-th:nth-child(2) {
    width: 20%;
  }

  .mo-table-main .mo-th:nth-child(3) {
    width: 24%;
  }

  .order-inner-main .order-sub-title {
    font-size: 18px;
    line-height: 20px;
  }

  .deliver-address-box .deliver-address-text {
    font-size: 18px;
    line-height: 20px;
  }

  .po-acc-summary .po-sub-title {
    font-size: 18px;
    line-height: 20px;
  }

  .order-inner-main .order-sub-data {
    font-size: 16px;
    line-height: 19px;
  }

  .mycart-table-main .th-mycart {
    font-size: 18px;
    line-height: 20px;
  }

  .header-logo-img {
    width: 86px;
    height: 70px;
  }

  .sec-antic-title {
    font-size: 31px;
    line-height: 43px;
  }

  .antic-jewelry-title {
    font-size: 26px;
    line-height: 34px;
  }

  .antic-jewelry-img2 {
    height: 370px;
  }

  .antic-jewelry-img3 {
    height: 415px;
  }

  .antic-jewelry-img {
    height: 421px;
  }

  .sec-main-title {
    font-size: 30px;
    line-height: 27px;
    margin: 0px 0px 25px;
  }

  .product-price {
    font-size: 18px;
    line-height: 24px;
  }

  .product-title {
    font-size: 16px;
    line-height: 15px;
  }

  .product-btn-box .btn-product {
    font-size: 16px;
    line-height: 17px;
  }

  .top-deals-title {
    font-size: 25px;
    line-height: 32px;
  }

  .top-deals-btn-box .top-deals-btn {
    font-size: 16px;
    line-height: 20px;
    max-width: 111px;
    height: 37px;
  }

  .top-deals-sub-title {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 6px;
  }

  .jewelry-text {
    font-size: 44px;
    line-height: 50px;
  }

  .banner-title {
    font-size: 46px;
    line-height: 58px;
  }

  .banner-sub-title {
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 9px;
  }

  .header-link .header-link-text {
    font-size: 15px;
    line-height: 16px;
  }

  .banner-right-img-box::after {
    height: 130px;
  }

  .header-cart-bg {
    height: 35px;
    width: 35px;
  }

  .header-user-bg {
    height: 35px;
    width: 35px;
  }

  .banner-left-img-box {
    height: 50vh;
    min-height: 500px;
  }

  .banner-left-img {
    height: 50vh;
    min-height: 500px;
  }

  .banner-right-img-box {
    height: 50vh;
    min-height: 500px;
  }

  .bg-overlay {
    height: 50vh;
    min-height: 500px;
  }

  .banner-right-img {
    height: 50vh;
    min-height: 500px;
  }

  .title-faq-asked .frequently-text {
    font-size: 33px;
    line-height: 26px;
    margin-bottom: 33px;
  }

  .tabs div {
    display: flex;
    flex-wrap: wrap;
  }

  .uploadingproducts-sec {
    background-size: cover;
  }

  .uploading-info .uploading-inner-title::after {
    top: -34px;
  }

  .header-search-form-control {
    max-width: 260px;
  }

  .modal-content .modal-modal-title {
    font-size: 30px;
    line-height: 30px;
    margin: 38px 0px 25px;
  }

  .login-modal .css-8ttqws {
    padding: 18px 21px 18px;
  }

  .sec-antic-title-mobile {
    font-size: 32px;
    line-height: 40px;
    color: #484848;
    font-family: cambria-math;
    display: block;
  }

  .sec-antic-title {
    display: none;
  }

  .pagination-box {
    margin: 50px 0px;
  }

  .uploadingproducts-sec {
    background-size: cover;
    height: 400px;
  }

  .about-main-banner {
    height: 400px;
  }

  .faq-main-banner {
    height: 400px;
  }

  .goldinfo-sec {
    height: 400px;
  }

  .pg-main-title-box .pg-main-title {
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .myorder-sec {
    padding: 40px 0px 40px;
  }

  /* start mobile menu css */
  .navigation-main {
    display: none;
  }

  .mobile-menu-main {
    display: block;
  }

  .mobile-logo-img {
    height: 102px;
    width: 94px;
    object-fit: contain;
  }

  .mobile-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger-menu {
    width: 25px;
    height: 25px;
    display: block;
  }

  .mobile-menu-drawer-tablet {
    display: block;
  }

  .mobile-menu-bg .mobile-nav-ul {
    padding: 0px;
  }

  .mobile-nav-ul .mobile-nav-items {
    padding: 0px;
  }

  .mobile-nav-ul .mobile-nav-button {
    color: #000000;
    font-family: 'mulish-bold';
    font-size: 14px;
    line-height: 19px;
  }

  .mobile-inner-nav-items .mobile-inner-nav-button {
    padding: 0px;
    color: #c29958;
    font-family: 'mulish-semibold';
    font-size: 14px;
    line-height: 18px;
  }

  .mobile-sub-menu-list .mobile-nav-sub-item {
    padding: 5px 15px;
  }

  .mobile-nav-sub-item .mobile-nav-sub-text span {
    margin: 0px;
    color: #484848;
    font-size: 15px;
    line-height: 12px;
  }

  .mobile-menu-drawer .mobile-nav-btn {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .header-vertical-line:after {
    top: 1px;
  }

  .header-right-main {
    margin-left: auto;
  }

  .search-customer-main {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-nav-ul {
    padding: 0px !important;
    min-width: 260px !important;
  }

  .header-tablet-search-main {
    display: block;
    margin-left: auto;
  }

  .header-main {
    display: none;
  }

  .mobile-search-box {
    display: none;
  }

  /* end mobile menu css */
  .desc-lorem-blog .desc-blog-details {
    font-size: 18px;
    line-height: 28px;
  }

  .quenty-btn-box .qty-btn-group {
    margin: 0px 30px;
  }

  .myproduct-sec {
    padding: 40px 0px;
  }
}

/* =============================================================================================================
============================================================================================================== */
@media screen and (min-width: 0px) and (max-width: 599px) {
  .main-payment-table {
    width: 365px;
    margin: auto;
  }

  .trending-sec {
    background-size: contain;
    padding: 50px 0px;
  }

  .catalogues-sec {
    background-size: contain;
    padding: 50px 0px;
  }

  .precious-sec {
    background-size: contain;
    padding: 50px 0px;
  }

  .product-img {
    max-width: 100%;
    height: 370px;
  }

  .antic-jewelry-last-box {
    margin-top: 25px;
  }

  .antic-jewelry-box {
    margin: 25px 0px 0px;
  }

  .antic-jewelry-sec {
    padding: 50px 0px;
    background-size: contain;
  }

  .banner-right-img-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    min-height: 400px;
    height: 40vh;
    position: absolute;
  }

  .banner-right-img {
    min-height: 400px;
    height: 40vh;
  }

  .banner-left-img {
    min-height: 400px;
    height: 40vh;
  }

  .banner-left-img-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    min-height: 400px;
    height: 40vh;
  }

  .bg-overlay {
    min-height: 400px;
    height: 40vh;
  }

  .banner-main {
    flex-direction: column;
  }

  .payment-main {
    flex-direction: column;
  }

  .payment-col-1 {
    min-width: 100%;
    flex: 0 0 100%;
    margin-right: 0px;
  }

  .expit-security-main {
    flex-direction: column;
  }

  .flex-inner-grid {
    flex-direction: column;
    display: block;
    width: 87%;
  }

  .payment-form-content {
    padding: 32px 14px 12px;
  }

  .payment-dropdown {
    margin-right: 0px;
  }

  .dropdown-select {
    min-width: 310px;
  }

  .questionmark-img {
    right: -31px;
  }

  .security-flex {
    margin-left: 0px;
  }

  .order-content-box {
    flex-direction: column;
  }

  .accordian-order-content-main {
    flex-direction: column;
    padding: 0px 10px;
  }

  .order-inner-main .order-sub-title {
    font-size: 17px;
    line-height: 18px;
  }

  .po-acc-summary .po-sub-title {
    font-size: 17px;
    line-height: 18px;
  }

  .or-track-btn-box .or-track-btn {
    margin-right: 0px;
  }

  .addinfo-text-box .addinfo-text {
    font-size: 20px;
    line-height: 20px;
    margin: 0px 0px 20px;
  }

  .radio-text-box .radio-text {
    font-size: 17px;
    line-height: 22px;
  }

  .cart-total-box .cart-total-title {
    font-size: 17px;
    line-height: 18px;
  }

  .mycart-table-main .th-mycart {
    font-size: 17px;
    line-height: 18px;
  }

  .po-return-btn-box .po-return-btn {
    margin-right: 0px;
  }

  .po-dropdown .po-form-control {
    min-width: 146px;
  }

  .deliver-address-para-box .deliver-address-para {
    padding-left: 0px;
  }

  .po-accordian .po-acc-details {
    padding: 20px 0px 10px 0px;
  }

  .mo-tabs-main .mo-tabs-lable {
    max-width: 169px;
  }

  .mo-table-main .custom-th {
    padding: 11px 10px;
    font-size: 17px;
    line-height: 18px;
  }

  .mo-table-main .th-recieved {
    padding: 11px 10px;
    font-size: 17px;
    line-height: 18px;
  }

  .myorder-view-btn-box .myorder-view-btn {
    max-width: 109px;
  }

  .mo-table-main .mo-th {
    padding: 11px 10px;
    font-size: 17px;
    line-height: 18px;
  }

  .mo-table-main .mo-th:nth-child(1) {
    width: 24%;
  }

  .next-btn-box .next-btn {
    max-width: 133px;
  }

  .save-btn-profile-box .save-btn-profile {
    max-width: 133px;
  }

  .myproduct-tabs .myproduct-tabs-lable {
    padding: 0px 15px;
  }

  .myproduct-tabs-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .my-product-tabs-main {
    margin-bottom: 15px;
  }

  .membership-title-box .become-title {
    font-size: 30px;
    line-height: 21px;
    margin-bottom: 22px;
  }

  .membership-content-main .become-inner-title {
    font-size: 18px;
    line-height: 29px;
  }

  .form-filing-main .form-filing-titles {
    font-size: 18px;
    line-height: 29px;
  }

  .form-filing-main {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .terms-text {
    margin: 30px 0px 30px 0px !important;
  }

  .membership-content-main {
    margin: 30px 0px;
  }

  .hero-title-box .hero-inner-title {
    font-size: 38px;
    line-height: 34px;
  }

  .ringsinfo-sub-title-box .ringsinfo-sub-title {
    font-size: 18px;
    line-height: 27px;
  }

  .ringstop-sub-title {
    margin: 25px 0px 17px 0px !important;
  }

  .faq-title-main .faq-typo-custom {
    line-height: 52px;
    font-size: 41px;
  }

  .rings-info-top-sec {
    margin: 50px 0px 30px;
  }

  .title-lorem-blog-main .title-blog-details {
    font-size: 18px;
    line-height: 25px;
  }

  .blogs-guideline-main .box-card-about-details {
    padding-bottom: 10px;
  }

  .website-titles .how-website-text {
    font-size: 29px;
    line-height: 21px;
    margin-bottom: 28px;
  }

  .website-inner-details {
    padding-bottom: 0px;
  }

  .website-blogs-main {
    padding: 50px 0px 10px 0px;
  }

  .about-set-details .about-sets-title {
    font-size: 30px;
    line-height: 25px;
    margin-top: 35px;
    margin-bottom: 14px;
  }

  .details-sets-us-text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 17px;
  }

  .why-choose-main {
    padding: 30px 0px 30px 0px;
  }

  .titile-inside-why .us-inner-why-chose {
    font-size: 28px;
    line-height: 16px;
    margin-bottom: 18px;
  }

  .testimonial-about-us {
    padding: 30px 0px 30px 0px;
  }

  .main-flex-text {
    flex-direction: column;
  }

  main .team-title {
    font-size: 35px;
    line-height: 31px;
  }

  .about-img-set {
    height: 300px;
  }

  .about-uslorem-main {
    padding: 50px 0px 10px;
  }

  .inner-box-aboutus .title-card-abouts {
    font-size: 18px;
    margin: 0px 0px 8px 0px;
    line-height: 22px;
  }

  .testimonial-titles-main .testimonial-title,
  .ourteam-titles-main .team-title {
    font-size: 29px;
    margin: 0px 0px 7px 0px;
    line-height: 30px;
  }

  .about-card .body-about-card {
    padding: 20px 18px 20px 18px;
  }

  .set-lorem-aboutus .aboutus-inner-set {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 4px;
  }

  .titile-inside-why .map-inner-why-chose,
  .testimonial-titles-main .testimonial-details-inner,
  .ourteam-titles-main .team-details-inner {
    font-size: 15px;
    margin-bottom: 19px;
    line-height: 22px;
  }

  .titile-inside-why .map-inner-why-chose,
  .testimonial-titles-main .testimonial-details-inner,
  .ourteam-titles-main .team-details-inner {
    font-size: 19px;
    margin-bottom: 0px;
    line-height: 29px;
  }

  .textimonial-quote-top .textimonialquote-top {
    max-width: 44.67px;
    height: 26px;
  }

  .text-quote-testimonial .p-text-quote {
    font-size: 18px;
    line-height: 22px;
    margin-top: 9px;
  }

  .our-team-about-us {
    padding: 31px 0px 166px 0px;
  }

  .user-aboutus-details {
    margin-top: -120px;
  }

  .team-img-main .img-team {
    height: 158px;
    width: 158px;
  }

  .footer-sec {
    padding: 50px 10px 22px;
  }

  .product-box {
    max-width: 345px;
  }

  .top-deals-img {
    height: 350px;
    max-width: 345px;
  }

  .top-deals-details-box {
    right: 0px;
  }

  .top-deals-sec {
    padding: 0px;
  }

  .title-faq-asked .frequently-text {
    font-size: 28px;
    line-height: 49px;
    margin-bottom: 26px;
  }

  .faq-accrodions .typo-faq-accro {
    font-size: 16px;
    line-height: 21px;
  }

  .desc-lorem-blog .desc-blog-details {
    font-size: 17px;
    line-height: 28px;
  }

  .faq-question-main {
    padding: 36px 0px 56px 0px;
  }

  .requestproduct1-content .thankyou-para {
    text-align: center;
  }

  .requestproduct1-content .thankyou-text {
    font-size: 35px;
    line-height: 26px;
  }

  .side-filter-main {
    max-width: 347px;
    margin-bottom: 35px;
  }

  .tabs div {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination-box-mens-womens {
    margin: 39px 0px 39px 0px;
  }

  .uploading-info .uploading-inner-title {
    font-size: 34px;
    line-height: 55px;
    padding-left: 21px;
  }

  .uploadingproducts-sec {
    background-size: cover;
    height: 400px;
  }

  .goldinfo-content .goldinfo-sub-text {
    font-size: 22px;
    line-height: 29px;
  }

  .footer-title {
    font-size: 18px;
    line-height: 34px;
  }

  .flex-colum-form {
    flex-direction: column;
  }

  .membership-title {
    font-size: 27px;
    line-height: 35px;
    padding-top: 17px;
    margin: 0px 0px 20px 0px;
  }

  .bank-details-title {
    font-size: 18px;
    line-height: 29px;
  }

  .footer-title::after {
    height: 2px;
  }

  .footer-copyright-sec {
    height: 45px;
  }

  .membership-sec {
    padding: 19.5px 0px 0px;
  }

  .faq-inner-content .lot-text {
    font-size: 22px;
    letter-spacing: 6px;
    line-height: 26px;
    margin-bottom: 54px;
  }

  .title-inner-help .how-help-text {
    font-size: 43px;
    line-height: 43px;
    margin-bottom: 18px;
  }

  .upper-content-services .title-typo-services {
    font-size: 20px;
    line-height: 28px;
  }

  .blog-guidelines-title .guideline-text {
    font-size: 37px;
    margin-bottom: 22px;
  }

  .box-card-about-details .about-card {
    margin-bottom: 30px;
  }

  .ringsinfo-img-box {
    height: 350px;
  }

  .rings-info-content .ringsinfo-para {
    font-size: 17px;
    line-height: 30px;
  }

  .textarea-main .addinfo-textarea {
    width: 100%;
    max-width: 100%;
  }

  .addinfo-img-box {
    width: 345px;
    height: 372px;
  }

  .requestproduct-overlay {
    width: 345px;
    height: 372px;
  }

  .addinfo-banner-text {
    font-size: 29px;
    line-height: 37px;
    min-height: 371px;
    text-align: center;
  }

  .addinfo-text-box {
    margin-top: 30px;
  }

  .rings-left-banner-img {
    width: 350px;
    height: 250px;
  }

  .seler-certi-text-box .seler-serti-text {
    font-size: 27px;
    line-height: 28px;
    margin: 0px 0px 30px;
  }

  .product-desc-sec {
    margin: 40px 0px;
  }

  .product-desc-title-box .product-desc-title {
    font-size: 22px;
    line-height: 25px;
  }

  .product-heading {
    font-size: 18px;
    line-height: 20px;
  }

  .product-data {
    font-size: 16px;
    line-height: 21px;
  }

  .quenty-btn-box .qty-btn-group {
    margin-left: 4px;
    margin-right: 21px;
  }

  .flower-text-box .flower-title {
    font-size: 22px;
    line-height: 30px;
    margin-top: 35px;
  }

  .flower-text-box .rings1-price-text {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 6px;
  }

  .rings1-img {
    width: 94px;
    height: 105px;
  }

  .rings1-img-box {
    height: 105px;
    margin: 20px 19px 0px 0px;
  }

  .primary-btn-box .add-product-btn {
    max-width: max-content;
    min-width: max-content;
    padding: 0px 20px;
  }

  .seler-certi-sec {
    padding-bottom: 40px;
  }

  .comment-img {
    height: 60px;
    width: 60px;
  }

  .comment-title-box .comment-text {
    font-size: 32px;
    line-height: 26px;
  }

  .addproduct-sec {
    padding: 27px 0px 40px;
  }

  .precious-pagination {
    margin: 40px 0px 40px !important;
  }

  .myorder-sec {
    padding: 15px 0px 45px;
  }

  .deliver-address-box .deliver-address-text {
    font-size: 18px;
    line-height: 20px;
  }

  .order-inner-main .order-sub-data {
    font-size: 15px;
    line-height: 20px;
  }

  .order-place-sec {
    padding: 24px 0px 42px;
  }

  .mycart-sec {
    padding: 16px 0px 48px 0px;
  }

  .mycart-table-main .td-mycart {
    font-size: 15px;
    line-height: 20px;
  }

  .payment-sec {
    padding: 19px 0px 54px;
  }

  .header-search-input,
  .search-main-header {
    position: relative;
    max-width: 315px;
  }

  .header-search-input .form-group {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background-color: #ffffff;
    border-radius: 25px;
  }

  .navigation-sec {
    border: 0px;
  }

  .header-logo-img {
    display: none;
  }

  .antic-jewelry-title {
    font-size: 26px;
    line-height: 34px;
  }

  .antic-jewelry-img2 {
    height: 370px;
  }

  .antic-jewelry-img3 {
    height: 415px;
  }

  .antic-jewelry-img {
    height: 421px;
  }

  .sec-main-title {
    font-size: 30px;
    line-height: 27px;
    margin: 0px 0px 25px;
  }

  .product-price {
    font-size: 18px;
    line-height: 24px;
  }

  .product-title {
    font-size: 16px;
    line-height: 15px;
  }

  .product-btn-box .btn-product {
    font-size: 16px;
    line-height: 17px;
  }

  .top-deals-title {
    font-size: 25px;
    line-height: 32px;
  }

  .top-deals-btn-box .top-deals-btn {
    font-size: 16px;
    line-height: 20px;
    max-width: 111px;
    height: 37px;
  }

  .top-deals-sub-title {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 6px;
  }

  .jewelry-text {
    font-size: 34px;
    line-height: 47px;
  }

  .banner-title {
    font-size: 34px;
    line-height: 47px;
  }

  .banner-sub-title {
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 9px;
  }

  .header-link .header-link-text {
    font-size: 15px;
    line-height: 16px;
  }

  .banner-right-img-box::after {
    height: 120px;
  }

  .header-cart-bg {
    height: 35px;
    width: 35px;
  }

  .header-user-bg {
    height: 35px;
    width: 35px;
  }

  .yellow-left-banner {
    height: 99px;
    width: 63px;
  }

  .tabs button {
    font-size: 14px;
    line-height: 17px;
    padding: 0px;
  }

  .hero-title-box .hero-inner-title::after {
    height: 85px;
    width: 62px;
    top: 84px;
  }

  .uploading-info .uploading-inner-title::after {
    height: 136px;
    width: 78px;
    left: 3px;
  }

  .sec-antic-title-mobile {
    font-size: 26px;
    line-height: 35px;
    color: #484848;
    font-family: cambria-math;
    display: block;
  }

  .sec-antic-title {
    display: none;
  }

  .pagination-box {
    margin: 50px 0px;
  }

  .search-customer-main {
    min-width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area-control-main .text-area-control3 {
    /* max-width: 536px; */
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    color: #8a8a8f;
    font-family: mulish-semibold;
    position: relative;
    margin-top: 15px;
  }

  .text-area-control3:focus {
    /* max-width: 536px; */
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    color: #8a8a8f;
    font-family: mulish-semibold;
    position: relative;
    margin-top: 15px;
    outline-color: #1565c0 !important;
  }

  /* start mobile menu css */
  .navigation-main {
    display: none;
  }

  .mobile-menu-main {
    display: block;
  }

  .mobile-logo-img {
    height: 70px;
    width: 70px;
    object-fit: contain;
  }

  .mobile-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger-menu {
    width: 25px;
    height: 27px;
    display: block;
  }

  .header-main {
    display: none;
  }

  .mobile-menu-bg .mobile-nav-ul {
    padding: 0px;
  }

  .mobile-nav-ul .mobile-nav-items {
    padding: 5px 0px 0px;
  }

  .mobile-nav-ul .mobile-nav-button {
    color: #000000;
    font-family: 'mulish-bold';
    font-size: 14px;
    line-height: 19px;
  }

  .mobile-nav-ul {
    padding: 0px !important;
    min-width: 260px !important;
  }

  .mobile-inner-nav-items .mobile-inner-nav-button {
    padding: 0px;
    color: #c29958;
    font-family: 'mulish-semibold';
    font-size: 14px;
    line-height: 18px;
  }

  .mobile-sub-menu-list .mobile-nav-sub-item {
    padding: 5px 15px;
  }

  .mobile-nav-sub-item .mobile-nav-sub-text span {
    margin: 0px;
    color: #484848;
    font-size: 14px;
    line-height: 10px;
    font-family: 'mulish-semibold';
  }

  .mobile-menu-drawer .mobile-nav-btn {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .modal-content .modal-modal-title {
    font-size: 22px;
    line-height: 11px;
    text-align: center;
    margin: 16px 0px 16px;
  }

  .modal-content .modal-footer-para {
    font-size: 15px;
    line-height: 11px;
  }

  .modal-logo-img {
    height: 64px;
    width: 116px;
  }

  .modal-btn-box .modal-btn {
    margin: 9px 0px 20px;
    font-size: 17px;
    height: 40px;
  }

  .forgot-password-box .forgot-password-text {
    margin-bottom: 0px;
  }

  .login-modal .css-8ttqws {
    padding: 20px;
    max-width: 300px;
    top: 48%;
  }

  .header-user-img {
    height: 29px;
    width: 26px;
    object-fit: contain;
  }

  .mobile-modal {
    display: none;
  }

  .mobile-search-box {
    margin-left: auto;
  }

  .search-main-header {
    display: block;
  }

  .mobile-none-search-img {
    display: none;
  }

  .header-tablet-search-main {
    display: none;
  }

  .header-mobile-search-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    background-color: #ffffff;
    margin-top: 21px;
  }

  .mobile-search-popup {
    left: 0 !important;
    right: 0 !important;
  }

  .mobile-search-img {
    display: none !important;
  }

  /* end mobile menu css */
  .about-main-banner {
    height: 400px;
  }

  .faq-main-banner {
    height: 400px;
  }

  .goldinfo-sec {
    height: 400px;
  }

  .pg-main-title-box .pg-main-title {
    font-size: 28px !important;
    line-height: 25px !important;
  }

  .myproduct-title-box .myproduct-title {
    font-size: 28px;
    line-height: 8px;
  }

  .addproduct-1-sec {
    padding: 40px 0px;
  }

  .order-modal .css-8ttqws {
    padding: 20px;
    max-width: 300px;
    top: 48%;
  }

  .order-radio-box .radio-group {
    flex-wrap: wrap;
  }

  .text-area-control-main .text-area-control {
    max-width: 368px;
  }

  .addproduct-textarea-main textarea {
    max-width: 100%;
    width: 100% !important;
  }
}

.nav-inner-main {
  column-count: 3;
  display: block;
}

.nav-jewelry-one:last-child {
  /* background: #faf5ef; */
  height: 559px;
}

.product-img-design {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  height: 130px !important;
  width: 200px !important;
  border-radius: 0px !important;
}

.product-img-box {
  position: relative;
}

.product-img-box .product-top-btn {
  /* min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  right: 5px;
  top: -15px; */

  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  visibility: hidden;
  right: -30px;
  top: -6px;
  cursor: pointer;
}

.product-img-box .product-download-btn {
  /* min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  left: 86px;
  top: 100px;
  visibility: hidden;
  color: #c29958;
  cursor: pointer; */

  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  /* left: 86px; */
  right: -30px;
  top: 30px;
  visibility: hidden;
  color: #c29958;
  cursor: pointer;
}

.product-img-box .product-download-btn1 {
  /* min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  left: 86px;
  top: 100px;
  visibility: hidden;
  color: #c29958;
  cursor: pointer; */

  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  /* left: 86px; */
  right: -30px;
  top: -3px;
  visibility: hidden;
  color: #c29958;
  cursor: pointer;
}

.product-img-box .product-download-box {
  /* min-width: 30px;
  width: 200px;
  padding: 0px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: 100px;
  visibility: hidden;
  background-color: white;
  opacity: 40%; */

  min-width: 30px;
  width: 35px;
  padding: 0px;
  height: 130px;
  position: absolute;
  /* left: 0px; */
  right: -35px;
  top: 0;
  /* visibility: hidden; */
  /* background-color: white; */
  opacity: 40%;
}

.product-img-box:hover .product-download-btn {
  visibility: visible;
}

.product-img-box:hover .product-download-btn1 {
  visibility: visible;
}

.product-img-box:hover .product-top-btn {
  visibility: visible;
}

.product-img-box:hover .product-download-box {
  visibility: visible;
}

.aboutUs-img-box .aboutUs-top-btn {
  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  left: 165px;
  top: -15px;
}

.product-img-box .item-top-btn {
  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  right: 141px;
  top: 7px;
}

.product-img-box .category-top-btn {
  min-width: 30px;
  padding: 0px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: -18px;
}

.product-details-btn-box {
  justify-content: flex-end !important;
}

.view-product-image {
  pointer-events: none;
}

.view-product-img-box {
  display: flex;
  align-self: center;
  width: 99%;
}

.admin-product-box {
  margin-right: 10px;
  height: 200px;
  width: 200px;
}

.order-main-row {
  display: flex;
  width: 100%;
}

.order-divide-row {
  max-width: 366px;
  width: 100%;
}

.po-inner-accordian-main .po-inner-accordian-summary {
  background-color: #f6f6f6 !important;
  padding: 20px 0px 0px;
  align-items: baseline;
}

.po-inner-accordian-main {
  margin: 10px 0px 0px;
  padding: 0px 0px 0px 10px;
}

.order-last-box {
  display: flex;
  flex-direction: column;
}

.order-inner-main-last {
  margin-bottom: 10px !important;
}

.order-btn-end-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.po-accordian .po-acc-details:first-child {
  padding-left: 0px;
}

.order-divide-row:nth-child(2) {
  max-width: 278px;
}

.common-button {
  margin-left: 8px !important;
}

.mange-user-content .manage-user-titles {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}

.mange-user-content {
  padding: 30px;
}

.manage-user-data-box {
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 20px;
}

.manage-user-data-box .manage-user-titles {
  font-size: 20px;
  line-height: 25px;
  margin-right: 10px;
}

.manage-user-data-box .manage-user-data-text {
  font-size: 15px;
  line-height: 20px;
  margin-left: 5px;
}

.sidebar-logout-btn-box .sidebar-logout-btn {
  padding: 0;
  font-size: 16px;
}

.mange-user-content .text-header-manageuser {
  margin: 40px 0px;
  font-size: 35px;
  line-height: 30px;
  font-weight: 100;
}

.main-return-box {
  display: flex;
  justify-content: center !important;
}

.text-return textarea {
  width: 100%;
  height: 82px !important;
  border: 1.5px solid #dfdfdf;
  resize: none;
  font-family: mulish-regular;
  overflow: auto !important;
  font-size: 15px;
  color: #484848;
  outline: none;
  padding: 8.5px;
}

.ineer-menu {
  background: #fff;
  display: none;
  position: fixed;
  margin-left: 265px;
  margin-top: 51px;
  min-width: 259px;
}

.ineer-menu .side-menu-list2 {
  padding: 0px;
  border-top: 4px solid #c29958;
}

/* .ineer-menu{
  position: relative;
} */
/* .inner-menu-set{
  display: block;
} */

li.inner-menu-set:hover .ineer-menu {
  display: block !important;
}

.main-side-menu-set .side-menu {
  overflow-y: initial;
}

.guidelines-image:hover {
  width: 200px;
  height: 200px;
}

.nav-inner-blog-count {
  column-count: 2 !important;
}

.bank-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13.63px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 13.65px;
  color: #484848;
  line-height: 21px;
  font-size: 17px;
  font-family: mulish-regular;
}

.payment-card-box-inner {
  max-width: 600px;
  width: 100%;
  justify-content: center;
  margin: auto;
}

.payment-text-field {
  width: 600px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.payment-input-lable {
  width: 600px;
  display: flex;
  margin: auto;
}

.payment-note {
  color: #000000;
  font-size: 15px;
  line-height: 25px;
  font-family: mulish-bold;
  margin: 20.3px 0px 17px 0px;
}

.payment-text-field-main {}

.payment-main-card-box {
  padding-top: 40px;
}

.membership-formcontrol-cms {
  width: 100%;
}

.nested-list-subheader-sidebar {
  margin-top: -18px !important;
}

.persentage-icon {
  position: absolute;
  height: 15px;
  width: 15px;
  object-fit: contain;
  z-index: 99;
  right: 18%;
  top: 50%;
}

.rupee-icon {
  height: 10px;
  width: 10px;
  margin-left: 5px;
}

.rupee-icon1 {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  color: white !important;
}

.login-zaverica-logo {
  height: 100px;
  width: 100px;
  margin: 10px auto;
  display: flex;
}

.Datepicker-order-description {
  display: flex;
  width: '200px';
}

.pagination-table-header-row {
  background-color: #000 !important;
  color: #fff !important;
}

.delivery-date-picker {
  height: 55px;
  border-radius: 3px;
  border-color: black !important;
  width: 100%;
  max-width: 243px;
}

.delivery-date-picker fieldset {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.delivery-date-picker .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.87) !important;
}

.delivery-list-item .delivery-text {
  max-width: max-content;
  width: 100%;
  margin-right: 11px;
}

.filter-dropdown-btn {
  width: 25px;
  min-width: 25px !important;
}

.membership-form-or-text {
  font-size: 14px;
  line-height: 18px;
  color: #b8b8b8;
  font-family: mulish-semibold;
  margin: 40px 0px 6px;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.loading-diamond {
  width: 100px;
  height: 100px;
}

.table-purchase-order {
  width: 400px;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.tg td {
  border-color: black !important;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 6px 19px;
  word-break: normal;
}

.tg th {
  border-color: black !important;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 6px 19px;
  word-break: normal;
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
  width: 120px;
  height: 23px;
}

.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  height: 30px;
  vertical-align: top;
}

.purchase-order-table-head-color {
  background-color: #7699aa;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  min-height: 250px !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 250px !important;
}

.file-preview-main div {
  height: 300px !important;
  width: 385px !important;
  padding: 0px !important;
}

.file-preview-main div img,
.file-preview-main div object {
  height: 300px !important;
  width: 385px !important;
}

.remove-img-icon {
  color: #c29958;
  margin-top: 0px;
}

.updateitem-img-box .remove-btn-upadteitem {
  min-width: max-content;
  position: absolute;
  top: 0px;
  height: 20px;
  right: 0px;
}

.updateitem-img-box {
  position: relative;
  margin: auto;
  max-width: 300px;
}

.go-to-top-icon {
  color: #c29958;
}

.form-control input::-webkit-outer-spin-button,
.form-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control1 input::-webkit-outer-spin-button,
.form-control1 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control2 input::-webkit-outer-spin-button,
.form-control2 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-payment .payment-table {
  width: 100%;
  max-width: 100vw;
  margin: auto;
  overflow: auto;
}

.table-cell-elipsis {
  display: block !important;
  width: 105px;
  white-space: nowrap;
  overflow: hidden;
  padding: 27px 16px 26px !important;
  text-overflow: ellipsis;
  line-height: 0 !important;
}

/* .tooltip-main .admin-tooltip{
  max-width: 100% !important;
  margin-top: 3px !important ;
  font-family: mulish-regular !important;
} */
/* .tooltip-main
  .admin-tooltip:hover
  +.MuiTooltip-tooltip
  .css-ja5taz-MuiTooltip-tooltip {
  max-width: 100% !important;
} */

/* ======================================================================================================================
======================================================================================================================
======================================================================================================================
======================================================================================================================
====================================================================================================================== */


.blank-text {
  font-size: 12px;
  line-height: 20px;
  font-family: mulish-semibold;
  margin: 17px 15px 0px;
}

.light-blank-placeholder::placeholder {
  color: #dddddd;
  /* Light blank color */
}

/* 
.btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
} */
.login-close-icon {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-field-password input {
  border: 0px;
}

.login-title {
  width: 70%;
}

.main-form-question{
  margin: auto;
  max-width: 450px;
}

.custon-text-area-main .custon-text-area{
  max-width: 424px;
  margin-top: 0;
}